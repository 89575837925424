/* You can add global styles to this file, and also import other style files */
@import "_variables.scss";
@import "custom.scss";
@import "mixins.scss";
@import "primeflex/primeflex.scss";
@import "devkitAll.scss";
@import "appStyles.scss";
//@import "../assets/css/devkit-theme/theme.scss";

// @import "_theme.scss";

/*:root {
  font-family: "Helvetica Neue", Helvetica, sans-serif, Arial !important;
  font-size: 16px;
  margin: auto;
  body {
    margin: auto;
    overflow-x: hidden;
    line-height: 24px;
    color: $slate;
  }
}*/