.text-color-primary {
    color: $lighter-blue;
}

.text-color-secondary {
    color: $darker-grey;
}

.font-x-small {
    font-size: $font-small;
}

.font-small {
    font-size: $font-small;
}

.font-normal {
    font-size: $font-normal;
}

.font-large {
    font-size: $font-large;
}

.font-x-large {
    font-size: $font-x-large;
}

/**** General ****/
.p-component {
    font-family: "Helvetica Neue", Helvetica, sans-serif, Arial !important;
 }

.bg-cyan-50 {
    background-color: #e1f3f8 !important;
}

.bg-dark-blue {
    background-color: #003e7e !important;
}

.bg-info-blue {
    background-color: #0076c0 !important;
}

.bg-panel-gray {
    background-color: #8080801a !important;
}

.asterik{
    color:#c32026;
    font-size: 25px;
    vertical-align: text-top;
}

/**** Accordion ****/
 .p-accordion-header-text {
    font-family: "Helvetica Neue", Helvetica, sans-serif, Arial !important;
 }

/**** Table ****/

.p-datatable-header {
    background-color: $ocean-blue;
    color: $white;
}

th {
    background-color: $white;
    color: $whaleblue;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: $whaleblue;
}

table {
    font-family: "Helvetica Neue", Helvetica, sans-serif, Arial !important;
    font-size: $font-normal;
}

/**** Dialog ****/
.p-dialog-header {
    background: #0076c0;
    color: #ffffff;
    height: 10px;

    .p-dialog-title {
        color: #ffffff;
    }

    .p-dialog-header-icons {
        .p-dialog-header-icon {
            color: #ffffff;
        }
    }

    .p-dialog-header-icon:enabled:hover {
        background: transparent !important;
    }
}

.p-dialog {
    font-family: "Helvetica Neue", Helvetica, sans-serif, Arial !important;
}

/**** Buttons ****/
.p-button.p-button-link {
    color:#0076c0;
    font-weight: 500;
}

/**** Input ****/
input.ng-dirty.ng-invalid {
    border: 5px solid $red; /* Red border */
}

.p-inputtext, .p-dropdown-item {
   font-family: "Helvetica Neue", Helvetica, sans-serif, Arial !important;
   font-size: 0.875rem;
}

/**** Dropdown ****/
.p-dropdown {
    background: #ffffff;
    border: 1px solid $darker-grey;
    border-radius: 0;
}

p-dropdown .p-component {
    font-family: "Helvetica Neue", Helvetica, sans-serif, Arial !important;
    font-size: 0.875rem;
}