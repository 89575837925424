/* Following are global CSS custom properties for font, color and layout. */
:root {
  font-family: 'Helvetica Neue', Helvetica, sans-serif, Arial;
}

body {
  font-family: 'Helvetica Neue', Helvetica, sans-serif, Arial;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $slate;
  width: 100%;
  overflow-x: auto;
  margin: 0;
  padding: 0;

  p {
      margin-top: 6px;
      margin-bottom: 6px;
      line-height: 30px;
  }

  legend {
      font-size: 18px;
      font-weight: 600;
      padding: 0 6px 0 0;
      border: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
      width: 100%;
      margin: 9px 0 6px 0;
  }

  h1 {
      font-size: 24px;
      line-height: 36px;
  }

  h2 {
      font-size: 18px;
  }

  h3 {
      font-size: 18px;
  }

  h4 {
      font-size: 16px;
  }

  h5 {
      font-size: 14px;
  }

  h6 {
      font-size: 13px;
  }

  a:link {
      color: $link-active;
      text-decoration: none;
  }

  a:visited {
      color: $link-visited;
      text-decoration: none;
  }

  a:hover {
      color: $black;
      text-decoration: underline;
  }

  a:active {
      color: $link-active;
      text-decoration: none;
  }

  .no-padding {
    padding: 0;
  }

  .p-col-1,
  .p-col-2,
  .p-col-3,
  .p-col-4,
  .p-col-5,
  .p-col-6,
  .p-col-7,
  .p-col-8,
  .p-col-9,
  .p-col-10,
  .p-col-11,
  .p-col-12 {
    padding: 0 0 0.5em 0;
  }

  /* PrimeNG Ui Components Overrides */

  .ui-widget .ui-radiobutton .ui-radiobutton-icon {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 24px;
      height: 24px;
      margin-top: -8px;
      margin-left: -12px;
    }

  .content-wrapper .p-field-radiobutton{
    .ui-radiobutton-box.ui-state-active {
      margin-right: 0;
      margin-left: 0;
    }
    .ui-radiobutton-icon {
      width: 22px;
      height: 24px;
      margin-top: -6px;
      margin-left: -11px;
    }
  }

    .ui-widget .ui-radiobutton, .ui-widget.ui-radiobutton-label {
      vertical-align: top;
    }
    .ui-radiobutton-label {
      margin-left: 4px;
      margin-right: 20px;
    }

    .ui-radiobutton-box {
      margin-bottom: 16px;
    }

    .ui-radiobutton-box.ui-state-active,
    .ui-radiobutton-box.ui-state-focus.ui-state-active {
      background-color: transparent;
      color: $navy-blue;
      margin-bottom: 16px;
    }
    .ui-radiobutton .ui-icon-bullet,
    .ui-radiobutton .ui-icon-blank {
      font-size: 24px;
      margin-top: 1px;
    }

    .ui-chkbox-label {
      margin-left: 4px;
      margin-top: 8px;
    }

    .ui-calendar.ui-calendar-w-btn input {
      height: 32px;
    }

    .ui-calendar {
      width: 100%;
      height: 32px;
    }

    .ui-dropdown .ui-dropdown-trigger {
      border-left: none;
    }

    .ui-dropdown .ui-dropdown-label {
      padding-left: 9px;
      color: $slate;
    }

    // .ui-dropdown .ui-dropdown-trigger-icon {
    //   display: none;
    // }

    // .ui-dropdown .ui-dropdown-trigger {
    //   background: $white;
    //   background-image: url('../assets/images/arrow-both.png');
    //   background-position: right 10px center;
    //   background-repeat: no-repeat;
    //   background-size: 10px;
    //   color: $slate;
    // }

    .ui-button {
      background: $primary-blue;
    }

    .ui-dropdown {
      min-width: 10em;
      border: solid 1px $darker-grey;
      // height: 30px;
    }

    .ui-dropdown-item {
      line-height: 20px;
    }

    .ui-dropdown-label.ui-inputtext {
      padding: 3px;
    }

    .ui-listbox .ui-listbox-item {
      padding: 0;
    }

    .listbox-form-wrapper .ui-button {
      margin-top: 24px;
      padding: 4px;
    }

    .ui-multiselect-label-container {
      min-width: 220px;
    }

    .ui-multiselect .ui-multiselect-label {
      padding: 4px 12px 4px 6px;
    }

    .ui-inputswitch .ui-inputswitch-slider {
      -moz-transition: background-color 0.2s;
      -o-transition: background-color 0.2s;
      -webkit-transition: background-color 0.2s;
      transition: background-color 0.2s;
      border-radius: 30px;
      background: $light-grey;
    }

    .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider {
      background-color: $navy-blue;
    }

    p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box {
      border-bottom: 1px solid #d6d6d6
    }

    .ui-inputswitch .ui-inputswitch-slider:before {
      background-color: $white;
      height: 1.250em;
      width: 1.250em;
      left: .25em;
      bottom: .25em;
      border-radius: 50%;
      -webkit-transition: 0.2s;
      transition: 0.2s;
    }

    .ui-slider .ui-slider-handle {
      /* height: 24px;
      width: 24px; */
      background-color: $white;
      border: 2px solid $secondary-grey;
      -moz-border-radius: 100%;
      -webkit-border-radius: 100%;
      border-radius: 100%;
      -moz-transition: border-color 0.2s;
      -o-transition: border-color 0.2s;
      -webkit-transition: border-color 0.2s;
      transition: border-color 0.2s;
      transition-property: border-color;
      transition-duration: 0.2s;
      transition-timing-function: ease;
      transition-delay: 0s;
    }

    .ui-slider .ui-slider-horizontal .ui-slider-handle {
      position: absolute;
      top: -2px;
    }

    .ui-slider:not(.ui-state-disabled) .ui-slider-handle:hover {
      background-color: $lighter-blue;
      border: 2px solid $lighter-blue;
    }

    .ui-slider .ui-slider-range {
      position: absolute;
      z-index: 1;
      font-size: .7em;
      display: block;
      border: 0;
      background: $whaleblue;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
      border-radius: 50%;
    }

    .ui-tabview {
      padding: 0 .25em .25em .25em;
    }

    .ui-tabview .ui-tabview-nav > li.ui-state-default {
      background: none;
      border: 0;
    }

    .ui-tabview .ui-tabview-nav > li.ui-state-default a {
      background: none;
      color: $slate;
      font-weight: 400;
      font-size: 14px;
      border: 0; }
    .ui-tabview .ui-tabview-nav > li.ui-state-active a {
      background: $white;
      color: $slate;
      font-weight: normal;
      font-weight: 400;
      border-bottom: 2px solid $navy-blue;
    }
    .ui-tabview .ui-tabview-nav > li.ui-state-active a:hover,
    .ui-tabview .ui-tabview-nav > li.ui-state-default a:hover {
      text-decoration: none; }

  .ui-table-caption {
      padding: 3px 8px 3px 12px;
      border-bottom: 0;
      text-transform: uppercase;
      background: $lighter-blue;
      color: $white;
      text-align: left;
  }
  .ui-table .ui-table-thead>tr>th, .ui-table .ui-table-tbody>tr>td, .ui-table .ui-table-tfoot>tr>td {
    text-align: left;
  }
  .ui-table-resizable>.ui-table-wrapper {
    overflow-x: hidden;
  }
  .ui-state-highlight {
    border: 3px solid $slate;
  }
  .ui-table-resizable .ui-table-resizable-fit .ui-unselectable-text {
    .ui-state-highlight {
      border: 1px solid $slate;
    }
  }
  .ui-tabview .ui-tabview-panel {
    padding: 0;
  }
  p-tree > div > div > ul > p-treenode > li > div.ui-treenode-content {
    background: $light-cyan;
  }

  .ui-dropdown:not(.ui-state-disabled):hover {
    background: $white;
  }

  .ui-dropdown {
    background: $white;
  }

/* PrimeNG accordion icon/header customization */
.pi-angle-right,
.pi-angle-down {
  font-size: 24px;
}
.ui-accordion .ui-accordion-header a {
  position: relative;
  display: flex;
  align-items: center;
  .accordion-right-header {
    position: absolute;
    right: 18px;
  }
}

/* End of PrimeNG UI Components overrides */
  .access-error-page {
    text-align: center;
    margin: 0 20vw;
    padding: 20vh 8vw 35vh 8vw;
  }
}

.templateContainer {
  max-width: 100% !important;
}

.forYou li {
  white-space: inherit;
}

.header {
  margin-bottom: 0;
}

.hNav {
  margin-top: 30px;
}

.padding-left {
padding: 0 5px;
}

/* Buttons CSS */
/* Following CSS rules cannot be altered. */
.diws-sm-bold {
font-size: 14px;
font-weight: 600;
}
.form-button-wrapper {
display: flex;
flex-flow: row;
flex-wrap: wrap;
align-items: center;
 a:link,
 a:visited,
 a:hover {
    color: $white;
    text-decoration: none;
}
}
.link-button {
  background: none;
  text-decoration: underline;
  color: $ocean-blue;
  font-size: 16px;margin: 6px;
  cursor: pointer;
}
.primary,
.secondary,
.danger {
  color: $white;
  text-decoration: none;
  cursor: pointer;
  margin: 6px 6px 6px 0;
  border: 0;
  border-radius: 0;
}

.primary {
  background: $primary-blue;
}

.secondary {
  background: $secondary-grey;
}

.danger {
  background: $red;
}

.danger {
  background: $red;
}

.disabled {
  margin: 6px 6px 4px 0;
  padding: 10px;
  background: $cyan;
  color: $slate;
  opacity: 0.8;
  cursor: not-allowed;
  border: 0;
  box-shadow: none;
}

.primary.mini,
.secondary.mini,
.disabled.mini {
  padding: 4px 5px 3px 5px;
  font-size: 12px;
}

.primary.small,
.secondary.small,
.disabled.small {
  padding: 6px 7px 5px 7px;
  font-size: 12px;
}

.primary.default,
.secondary.default,
.danger.default,
.disabled.default {
  padding: 9px 15px 9px 15px;
  font-size: 14px;
}

.primary.large,
.secondary.large,
.danger.large,
.disabled.large {
  padding: 12px 15px 10px 15px;
  font-size: 18px;
}

.primary:hover,
.secondary:hover {
  background: $hover-blue;
}

.error-red {
  color: $red;
}

/* Following PrimeFlex overrides are needed to fix whole content shifting to the left due to negative margin. */
.p-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}

.p-col-1,
.p-col-2,
.p-col-3,
.p-col-4,
.p-col-5,
.p-col-6,
.p-col-7,
.p-col-8,
.p-col-9,
.p-col-10,
.p-col-11,
.p-col-12 {
-webkit-box-flex: 0;
-ms-flex: 0 0 auto;
flex: 0 0 auto;
padding: 0.5em 0 0.5em 0;
}
.p-col-9 {
  width: 100%;
}

.p-col-fixed {
padding: 0;
}

.ui-dialog-footer.ui-widget-content {
.primary {
    background: $primary-blue;
}
.secondary.default.ui-button.ui-widget {
  background: $secondary-grey;
}
.primary:hover,
.secondary.default.ui-button.ui-widget:hover {
  background: $hover-blue;
}
.primary.default,
.secondary.default.ui-button.ui-widget {
  padding: 3px 6px !important;
  font-size: 14px;
}
.disabled.default.ui-button.ui-widget {
  padding: 4px 6px !important;
  font-size: 14px;
  background: $cyan;
  color: $slate;
  border: none;
  border-radius: 0;
  cursor: not-allowed;
}
}

@media screen and (min-width: 960px) {
.p-col-9 {
    width: 75%;
}
}

/* End of PrimeFlex overrides */

/* You can add global styles to this file, and also import other style files */

.no-padding {
padding: 0;
}

.no-margin{
margin: 0;
}

.font-bold {
font-weight: 600;
}

.devkit-label-vertical-align {
display: flex;
align-items: center;
}

.text-align-center {
text-align: center;
}

.text-header-bold {
font-size: 1.25rem;
font-weight: 700;
}
.text-header-normal, .text-footer-normal {
font-size: 1rem;
font-weight: 600;
}
.text-normal-bold {
font-size: 1rem;
font-weight: 700;
}

.text-normal {
font-size: 1rem;
font-weight: 500;
}
.text-normal-small {
font-size: 0.75rem;
font-weight: 400;
}
.text-normal-light {
font-size: 1rem;
font-weight: 500;
color: $secondary-grey;
}

.text-normal-small-light {
font-size: 0.75rem;
font-weight: 400;
color: $secondary-grey;
}

.text-align-left {
text-align: left;
}

.text-align-right {
text-align: right;
}

.align-top{
vertical-align: top;
}

.align-bottom {
vertical-align: bottom;
}

.align-middle {
vertical-align: middle;
}

.float-left {
float: left;
}

.float-right  {
float: right;
}

.errorMsg {
text-align: center;
height: 60px;
display: block;
line-height: 60px;
top: 0;
left: 0;
right: 0;
width: 100%;
background: #F44336;
color: white;
position: fixed;
border-bottom: 4px #D32F2F solid;
font-weight: bold;
font-size: 16px;
-webkit-animation-name: notificationMsgCls; /* Safari 4.0 - 8.0 */
-webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
animation-name: notificationMsgCls;
animation-duration: 1s;
}

.errorMsgClose {
text-align: center;
height: 60px;
display: block;
line-height: 60px;
top: -60px;
left: 0;
right: 0;
width: 100%;
background: #F44336;
color: white;
position: fixed;
border-bottom: 4px #D32F2F solid;
font-weight: bold;
font-size: 16px;
-webkit-animation-name: notificationMsgClose; /* Safari 4.0 - 8.0 */
-webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
animation-name: notificationMsgClose;
animation-duration: 1s;
}

.successMsg {
text-align: center;
height: 60px;
display: block;
line-height: 60px;
width: 100%;
background: #8BC34A;
color: white;
position: fixed;
border-bottom: 4px #689F38 solid;
font-weight: bold;
font-size: 16px;
-webkit-animation-name: notificationMsg; /* Safari 4.0 - 8.0 */
-webkit-animation-duration: 4s; /* Safari 4.0 - 8.0 */
animation-name: notificationMsg;
animation-duration: 4s;
top: 0;
left: 0;
right: 0;
}

.ui-cell-data {
display: block;
overflow: hidden;
}
.closeBtn {
position: fixed;
top: -15px;
right: 10px;
color: white;
cursor: pointer;
font-size: 18px;
}

.containerAirportList .ui-dialog.ui-widget .ui-dialog-titlebar {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
}

/* Used to adjust spacing of all select input boxes. */

select {
  padding: 4px 0;
}

input {
border: solid 1px $darker-grey;
}

/* Currently, primeNG dynamic dialog does not support adding custom icons with a call back.
As a workaround, this div (devkit-dialog-close-button) is used to add a close icon to dynamic dialog header and a callback function to that icon using a click event handler.
It is scoped to devkit to be able to use in all applications. */
.devkit-dialog-close-button {
  position: fixed;
  top: 5px;
  right: 0;
  z-index: 5000;
  background: none;
  border: 0;
  color: white;
  padding: 0;
}

.devkit-dialog-close-button {
  .close-button{
    border: none;
    color: $slate;
    background: none;
  }
}

/* Dialog */
/* This is center align the modal buttons. */
.devkit-dialog-footer-wrapper {
position: relative;
padding: 12px;

.devkit-dialog-footer {
  display: flex;
  justify-content: center; //Change here if you want the buttons to be aligned left or right. It is aligned center for CMS
  border-top: 1px solid $lighter-grey;
  margin-top: 24px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
}

.p-col-12 {
  padding: 0 24px 6px 24px;
}
}
.display-none-fd {
display: none;
}

body .ui-toast .ui-toast-message.ui-toast-message-success {
background-color: $applegreen;
border-left: 11px solid $green;
/* color: #212121; */
}
body .ui-toast .ui-toast-message.ui-toast-message-info {
background-color: $light-cyan;
border-left: 11px solid $sky-blue;
}
body .ui-toast .ui-toast-message.ui-toast-message-error {
background-color: $tutu;
border-left: 11px solid $red;
}
body .ui-toast .ui-toast-message.ui-toast-message-warn {
background-color: $varden;
border-left: 11px solid $yellow;
}

.req-asterisk {
color: $red;
}

%text-shared {
// width: 86%;
padding: 0.429rem;
border: solid 1px $darker-grey;
font-size: 0.875rem;
color: $slate;
}

.diws-form-row-inline {
display: flex;
flex: row;
align-items: center;
label {
  font-size: 0.875rem;
  margin-right: 2px;
}
input[type="text"], input[type="number"] {
  @extend %text-shared;
}
input[type="text"]:disabled {
  @extend %text-shared;
  opacity: 0.6;
  cursor: not-allowed;
  // background: $background-grey;
}
input[type="date"] {
  @extend %text-shared;
}
select {
  width: 90%;
  padding: 9px 48px 9px 9px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: $white;
  background-image: url(../assets/images/arrow-both.png);
  background-position: right 8px center;
  background-repeat: no-repeat;
  background-size: 12px;
  color: $slate;
  font-size: 0.875rem;
  border: solid 1px $darker-grey;
}
select option {
  padding: 6px;
  font-size: 0.875rem;
}
select option:hover {
  background: $hover-blue;
  color: $white;
  padding: 10px 6px 0 6px;
}
.pDropDown option {
  padding: 6px;
  font-size: 0.875rem;
}
.pDropDown {
  .ui-dropdown {
    padding-right: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: $white;
    background-position: right 8px center;
    background-repeat: no-repeat;
    background-size: 12px;
    color: $slate;
    font-size: 0.875rem;
    border: solid 1px $darker-grey;
    width: 100%;
    white-space: pre;
    text-overflow: ellipsis
  }
}
}

.devkit-required {
font-size: 1.625rem;
color: $red;
vertical-align: middle;
}
.diws-btn {
display: flex;
align-items: center;
.diws-plus {
  margin-right: 6px;
  font-size: 22px;
  font-weight: 600;
}
}



.diws-plus {
margin-right: 6px;
font-size: 20px;
font-weight: 600;
}

.invalid-required {
width: 196px;
padding-top: 4px;
font-size: 0.75rem;
color: $red;
vertical-align: middle;
}

/*  Following are styles for form 8500-8*/
.content-wrapper {
padding: 12px 6px;
color: $slate;
.form-group-heading {
  font-size: 20px;
  font-weight: 600;
}
.form-items-end-align-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 6px;
}
.form-items-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 6px;
  .form-col-items-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 18px;
  }
}
.form-items-end-align-wrapper {
  input {
    height: 31px;
    border: solid 1px $darker-grey;
  }
  .form-item-wrapper {
    margin-right: 3%;
    label {
      display: block;
    }
  }
  .form-item-wrapper-inline {
    margin-right: 3%;
    label {
      display: inline-block;
    }
  }
  .form-item-chk-wrapper {
    margin-right: 3%;
  }
}
.form-items-wrapper {
  input {
    height: 31px;
    border: solid 1px $darker-grey;
  }
  .form-item-wrapper {
    margin-right: 3%;
    label {
      display: block;
    }
  }
  .form-item-chk-wrapper {
    margin-right: 3%;
  }
}
.form-panel-content-wrapper {
  margin: 9px auto;
  padding: 6px 12px;
  //background: $lightest-grey;
}
.buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 12px;
}

}
.diws-age-calc-ribbon {
margin: 6px auto;
padding: 6px;
//background: $lightest-grey;
}

.dialog-buttons-wrapper {
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
margin-top: 10px;
}

.diws-no-records {
font-size: 16px;
color: $red;
}

.border-wrap {
border: 1px #cccccc solid;
padding: 0px 10px;
margin: 5px;
}

//mid node type icon classes
.unsigned-correspondance-sm-icon {
  content: url('/assets/images/unsigned/unsigned-correspondance-sm.svg');
}
.unsigned-correspondance-bg-icon {
  content: url('/assets/images/unsigned/unsigned-correspondance-bg.svg');
}
.unsigned-correspondance-p-prev-sm-icon {
  content: url('/assets/images/unsigned/unsigned-print-preview-sm.svg');
}
.unsigned-correspondance-p-gen-sm-icon {
  content: url('/assets/images/unsigned/unsigned-print-generated-sm.svg');
}
.diws-cert-sm-icon {
  content: url('/assets/images/diws-cert/diws-cert-sm.svg');
}
.diws-cert-bg-icon {
  content: url('/assets/images/diws-cert/diws-cert-bg.svg');
}
.other-doc-sm-icon {
  content: url('/assets/images/other-doc/other-doc-sm.svg');
}
.other-doc-bg-icon {
  content: url('/assets/images/other-doc/other-doc-bg.svg');
}
.other-doc-p-prev-sm-icon {
  content: url('/assets/images/other-doc/other-doc-print-preview-sm.svg');
}
.other-doc-p-gen-sm-icon {
  content: url('/assets/images/other-doc/other-doc-print-generated-sm.svg');
}
.revised-sm-icon {
  content: url('/assets/images/revised/revised-sm.svg');
}
.revised-bg-icon {
  content: url('/assets/images/revised/revised-bg.svg');
}
.printed-sm-icon {
  content: url('/assets/images/printed/printed-sm.svg');
}
.printed-bg-icon {
  content: url('/assets/images/printed/printed-bg.svg');
}
.soda-sm-icon {
  content: url('/assets/images/soda/soda-sm.svg');
}
.soda-bg-icon {
  content: url('/assets/images/soda/soda-bg.svg');
}
.soda-p-prev-sm-icon {
  content: url('/assets/images/soda/soda-print-preview-sm.svg');
}
.soda-p-gen-sm-icon {
  content: url('/assets/images/soda/soda-print-generated-sm.svg');
}
.signed-correspondance-sm-icon-in-progress {
  content: url('/assets/images/signed/signed-sm.svg');
  background: orange;
  padding: 3px;
}
.signed-correspondance-sm-icon-pkg-completed {
  content: url('/assets/images/signed/signed-sm.svg');
  background: #00fff2;
  padding: 3px;
}
.signed-correspondance-sm-icon {
  content: url('/assets/images/signed/signed-sm.svg');
}
.signed-correspondance-bg-icon {
  content: url('/assets/images/signed/signed-bg.svg');
}
.signed-correspondance-p-prev-sm-icon {
  content: url('/assets/images/signed/signed-print-preview-sm.svg');
}
.signed-correspondance-p-gen-sm-icon {
  content: url('/assets/images/signed/signed-print-generated-sm.svg');
}

.icon-size.no-bkg.signed-correspondance-sm-icon-in-progress {
  content: url('/assets/images/signed/signed-sm.svg');
  background: none;
  padding: 0;
}

.icon-size.no-bkg.signed-correspondance-sm-icon-pkg-completed {
  content: url('/assets/images/signed/signed-sm.svg');
  background: none;
  padding: 0;
}

.N-0-icon {
  content: url('/assets/images/mid-folder-icons/0.png');
}
.N-0-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/0.png');
  background: aqua;
  padding: 3px;
}
.N-1-icon {
  content: url('/assets/images/mid-folder-icons/1.png');
}
.N-1-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/1.png');
  background: aqua;
  padding: 3px;
}
.N-2-icon {
  content: url('/assets/images/mid-folder-icons/2.png');
}
.N-2-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/2.png');
  background: aqua;
  padding: 3px;
}
.N-3-icon {
  content: url('/assets/images/mid-folder-icons/3.png');
}
.N-3-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/3.png');
  background: aqua;
  padding: 3px;
}
.N-4-icon {
  content: url('/assets/images/mid-folder-icons/4.png');
}
.N-4-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/4.png');
  background: aqua;
  padding: 3px;
}
.N-5-icon {
  content: url('/assets/images/mid-folder-icons/5.png');
}
.N-5-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/5.png');
  background: aqua;
  padding: 3px;
}
.N-6-icon {
  content: url('/assets/images/mid-folder-icons/6.png');
}
.N-6-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/6.png');
  background: aqua;
  padding: 3px;
}
.N-7-icon {
  content: url('/assets/images/mid-folder-icons/7.png');
}
.N-7-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/7.png');
  background: aqua;
  padding: 3px;
}
.N-8-icon {
  content: url('/assets/images/mid-folder-icons/8.png');
}
.N-8-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/8.png');
  background: aqua;
  padding: 3px;
}
.N-9-icon {
  content: url('/assets/images/mid-folder-icons/9.png');
}
.N-9-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/9.png');
  background: aqua;
  padding: 3px;
}
.N-10-icon {
  content: url('/assets/images/mid-folder-icons/10.png');
}
.N-10-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/10.png');
  background: aqua;
  padding: 3px;
}
.N-11-icon {
  content: url('/assets/images/mid-folder-icons/11.png');
}
.N-11-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/11.png');
  background: aqua;
  padding: 3px;
}
.N-12-icon {
  content: url('/assets/images/mid-folder-icons/12.png');
}
.N-12-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/12.png');
  background: aqua;
  padding: 3px;
}
.N-13-icon {
  content: url('/assets/images/mid-folder-icons/13.png');
}
.N-13-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/13.png');
  background: aqua;
  padding: 3px;
}
.N-14-icon {
  content: url('/assets/images/mid-folder-icons/14.png');
}
.N-14-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/14.png');
  background: aqua;
  padding: 3px;
}
.F-icon {
  content: url('/assets/images/mid-folder-icons/fyi.png');
}
.F-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/fyi.png');
  background: aqua;
  padding: 3px;
}
.C-icon {
  content: url('/assets/images/mid-folder-icons/copy.png');
}
.C-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/copy.png');
  background: aqua;
  padding: 3px;
}
.R-icon {
  content: url('/assets/images/mid-folder-icons/returned-copy.png');
}
.R-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/returned-copy.png');
  background: aqua;
  padding: 3px;
}
.in-workflow-icon {
  content: url('/assets/images/mid-folder-icons/in-workflow.png');
}
.in-workflow-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/in-workflow.png');
  background: aqua;
  padding: 3px;
}
.not-in-workflow-icon {
  content: url('/assets/images/mid-folder-icons/not-in-worklfow.png');
}
.not-in-workflow-icon-pkg-completed {
  content: url('/assets/images/mid-folder-icons/not-in-worklfow.png');
  background: aqua;
  padding: 3px;
}

.history-read {
  content: url("/assets/images/mid-folder-icons/exam-folder-no-doc.png");
  height: 16px;
  width: 16px;
}
.form-buttons-wrapper {
.form-buttons {
  .default {
    padding-left: 7px;
    padding-right: 7px;
  }
}
}

.diws-radio-btns-wrapper {
input {
  display: none;
}
> label {
  display: inline-block;
  position: relative;
  margin-right: 16px;
  padding-left: 24px;
  cursor: pointer;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 11px;
    height: 11px;
    left: 0;
    top: 2%;
    margin-top: -8px;
    border: 1px solid #fff;
    box-shadow: 0 0 0 2px $white, 0 0 0 3px $secondary-grey;
    border-radius: 8px;
  }
  // this creates the inner circle for active faux radio button
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 8px;
    margin-top: 0;
    background: #fff;
    border-radius: 4px;
    transition: .2s ease-in-out;
  }
}
}


.error-font{
  color:#c32026;
  font-size: 12px;
}
/* End of global CSS custom properties */
