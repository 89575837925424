//Generates margin utility classes
//Usage Example: <div class="devkit-m-12"></div>
//Compiles to: .devkit-m-12 { margin: 12px }

@for $i from 1 through 100 {
  $marginsize: 1px * $i;
  .devkit-m-#{$i} {
    margin: $marginsize;
  }
}

@for $i from 1 through 100 {
  $marginsize: 1px * $i;
  .devkit-m-top-#{$i} {
    margin-top: $marginsize;
  }
}

@for $i from 1 through 100 {
  $marginsize: 1px * $i;
  .devkit-m-right-#{$i} {
    margin-right: $marginsize;
  }
}

@for $i from 1 through 100 {
  $marginsize: 1px * $i;
  .devkit-m-bottom-#{$i} {
    margin-bottom: $marginsize;
  }
}

@for $i from 1 through 100 {
  $marginsize: 1px * $i;
  .devkit-m-left-#{$i} {
    margin-left: $marginsize;
  }
}

@for $i from 1 through 100 {
  $marginsize: 1px * $i;
  .devkit-m-rl-#{$i} {
    margin-right: $marginsize;
    margin-left: $marginsize;
  }
}

@for $i from 1 through 100 {
  $marginsize: 1px * $i;
  .devkit-m-tb-#{$i} {
    margin-top: $marginsize;
    margin-bottom: $marginsize;
  }
}

//Generates padding utility classes
//Usage Example: <div class="devkit-p-12"></div>
//Compiles to: .devkit-p-12 { padding: 12px }

@for $i from 1 through 100 {
  $paddingsize: 1px * $i;
  .devkit-p-#{$i} {
    padding: $paddingsize;
  }
}

@for $i from 1 through 100 {
  $paddingsize: 1px * $i;
  .devkit-p-top-#{$i} {
    padding-top: $paddingsize;
  }
}

@for $i from 1 through 100 {
  $paddingsize: 1px * $i;
  .devkit-p-right-#{$i} {
    padding-right: $paddingsize;
  }
}

@for $i from 1 through 100 {
  $paddingsize: 1px * $i;
  .devkit-p-bottom-#{$i} {
    padding-bottom: $paddingsize;
  }
}

@for $i from 1 through 100 {
  $paddingsize: 1px * $i;
  .devkit-p-left-#{$i} {
    padding-left: $paddingsize;
  }
}

@for $i from 1 through 100 {
  $paddingsize: 1px * $i;
  .devkit-p-rl-#{$i} {
    padding-right: $paddingsize;
    padding-left: $paddingsize;
  }
}

@for $i from 1 through 100 {
  $paddingsize: 1px * $i;
  .devkit-p-tb-#{$i} {
    padding-top: $paddingsize;
    padding-bottom: $paddingsize;
  }
}

$zero: 0;
$xs: 6px;
$small: 12px;
$medium: 24px;
$large: 36px;
$xlarge: 48px;
$xxlarge: 60px;

@mixin space($type: margin, $direction: all, $amount: $medium) {
  @if $type == padding {
    @if $direction == all {
      padding: $amount;
    } @else if $direction == top {
      padding-top: $amount;
    } @else if $direction == left {
      padding-left: $amount;
    } @else if $direction == right {
      padding-right: $amount;
    } @else {
      padding-bottom: $amount;
    }
  } @else {
    @if $direction == all {
      margin: $amount;
    } @else if $direction == top {
      margin-top: $amount;
    } @else if $direction == left {
      margin-left: $amount;
    } @else if $direction == right {
      margin-right: $amount;
    } @else {
      margin-bottom: $amount;
    }
  }
}

/* Sample Usage for @space
.test-class{
    color: #333333;
    @include space(margin, top, $small);
} 
*/

@mixin fontBold() {
  font-weight: 600;
  font-size: 20px;
}

/* Sample Usage for @fontBold
.test-class {
    @include fontBold();
*/

@mixin fontBoldItalics() {
  font-weight: 600;
  font-style: italic;
  font-size: 20px;
}

/* Sample Usage for @fontBoldItalics
.test-class {
    @include fontBoldItalics();
*/

@mixin fontItalicsNormal() {
  font-weight: 500;
  font-style: italic;
  font-size: 16px;
}

/* Sample Usage for @fontItalicsNormal
.test-class {
    @include fontItalicsNormal();
*/

@mixin mainWrapper() {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 24px 24px 36px 24px;
  @media screen and (min-width: 960px) {
    margin: 0 6px 0 12px;
    border-top: none;
    flex-direction: row;
  }
  @media screen and (min-width: 1280px) {
    margin: 12px 6px 0 9px;
  }
}
