@import '../scss/variables';

:root {
    margin: 0 auto;
}

/* Global CSS */
body {
    font-size: 16px;
    font-weight: 100;
    line-height: 24px;
    color: $slate;
    max-width: 100%;
    overflow-x: auto;
    margin: 0;
    padding: 0;
    overflow-x: hidden;

    p {
        margin-top: 6px;
        margin-bottom: 6px;
        line-height: 30px;
    }

    legend {
        font-size: 18px;
        font-weight: 600;
        padding: 0 6px 0 0;
        border: 0;
    }

    a:link {
        color: $link-active;
        text-decoration: none;
    }

    a:visited {
        color: $link-visited;
        text-decoration: none;
    }

    a:hover {
        color: $black;
        text-decoration: underline;
    }

     a:active {
        color: $link-active;
        text-decoration: none;
    }

     .loader {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;

         .fa-spinner,
         .loading-text {
            display: inline;

        }

         .fa-spinner {
            font-size: 28px;
        }

         .loading-text {
            padding-left: 6px;
            font-size: 18px;
            font-weight: 600;
        }
    }

     .devkit-help-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 35;
        background: $darker-grey;
        opacity: 0.65;
    }

     ul.devkit-info-list,
     ul.devkit-left-info-list,
     ul.devkit-right-info-list {
        list-style-type: none;
        padding-left: 0;
        margin-top: 0;
        line-height: 30px;
         li {
             a:link,
             a:visited {
                color: $link-active;
            }
             a:hover {
                color: $black;
                text-decoration: underline;
            }
             .fa-arrow-down {
                font-size: 14px;
                color: $darker-grey;
            }
        }
    }

     .devkit-info-list-wrapper {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        width: 600px;
    }

    .devkit-text-bold {
        font-weight: 600;
    }

    .devkit-text-italic {
        font-style: italic;
    }

    .devkit-blue-text {
        color: $primary-blue;
    }

    .devkit-code {
        font-size: 14px;
         h4 {
            margin-top: 0;
            margin-bottom: 12px;
        }

         p {
            line-height: 18px;
            margin-bottom: 0;
        }

         p:last-of-type {
            margin-bottom: 18px;
        }

         .devkit-code-attr {
            font-weight: 600;
        }
    }

     .devkit-main-head {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        color: $slate;
         .devkit-global-icon-wrapper {
            display: flex;
            align-items: center;
            margin-top: 9px;
            padding: 9px 12px;
            color: $darker-grey;
            cursor: pointer;
            background: $background-grey;
             .icon-text-wrapper {
                display: flex;
                align-items: center;
                 .fas.fa-print,
                 .fas.fa-share-alt {
                    font-size: 18px;
                }
                 .devkit-icon-text {
                    display: none;
                }
            }
             .icon-text-wrapper:first-of-type {
                margin-right: 12px;
            }
        }
    }

     .devkit-notes-wrapper {
        margin-bottom: 24px;
    }
}

@media screen and (min-width: 960px) {
    // body {
        legend {
            font-size: 20px;
        }
        .devkit-main-head {
            .devkit-global-icon-wrapper {
                margin-top: 4px;
                .icon-text-wrapper {
                     .devkit-icon-text {
                        display: inline;
                        padding-left: 6px;
                        font-family: 'Helvetica Neue', Arial, sans-serif;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
        }
        .devkit-info-list-wrapper {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            width: 600px;
        }
    // }
}

.zoomout {
    zoom: 85%;
}

/*  CSS for checkboxes and radio buttons */
[type="checkbox"],
[type="radio"] {
  opacity: 0;
  position: absolute;
}

[type="checkbox"] + label,
[type="radio"] + label {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 6px;
}

[type="checkbox"] + label::before,
[type="radio"] + label::before {
  background: $white;
  content: "";
  display: inline-block;
}

[type="checkbox"] + label::before {
  width: 16px;
  height: 16px;
  line-height: 32px;
  margin: 0 10px 10px 1px;
  border-radius: 1px;
  //box-shadow: 0 0 0 1px $checkbox-grey;
  vertical-align: top;
}

[type="radio"] + label::before {
  width: 16px;
  height: 16px;
  margin: 0 12px 12px 3px;
  border-radius: 100%;
  //box-shadow: 0 0 0 2px $white, 0 0 0 3px $checkbox-grey;
  // line-height: 22px;
  vertical-align: text-top;
}

[type="checkbox"]:checked + label::before,
[type="radio"]:checked + label::before {
  background-color: $navy-blue;
  box-shadow: 0 0 0 1px $navy-blue;
}

[type="radio"]:checked + label::before {
  box-shadow: 0 0 0 2px $white, 0 0 0 4px $navy-blue;
}

[type="checkbox"]:checked + label::before,
[type="checkbox"]:checked:disabled + label::before {
  background-image: url("../assets/images/correct8.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 12px;
}

// [type="radio"]:focus + label::before {
//   outline: 2px dotted $asbestos;
//   outline-offset: 6px;
// }

[type="checkbox"]:disabled + label {
  color: $light-grey;
}

// [type="checkbox"]:focus + label::before {
//   outline: 2px dotted $asbestos;
//   outline-offset: 3px;
// }

[type="checkbox"]:disabled + label::before,
[type="radio"]:disabled + label::before {
  background: $cyan;
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 3px $asbestos;
  // box-shadow: 0 0 0 1px $asbestos;
  cursor: not-allowed;
}

/* COMMON (INTERNAL/PUBLIC FACING) CSS*/
/* Accordion wrapper CSS */
// .devkit-al-wrapper {
//     display: flex;
//     flex-direction: column;
//     align-content: center;
// }

// @media screen and (min-width: 1280px) {
//     .devkit-al-wrapper {
//         flex-direction: row;
//         justify-content: space-between;
//     }
// }

/* Accordion CSS */
// .devkit-accordion-wrapper {
//     width: 430px;
//     margin: 0 0 24px 0;

//      .devkit-accordion-head {
//         font-size: 18px;
//         font-weight: 600;
//         cursor: pointer;
//         background: lighter-grey;
//         margin: 12px 0 0 0;
//         padding: 12px 12px 6px 12px;
//     }

//      .devkit-accordion-body {
//         display: none;
//         border: solid 2px lighter-grey;
//         padding: 12px;
//     }

//      .devkit-accordion-body {
//          p {
//             line-height: 24px;
//         }
//     }

//      .devkit-expand-collapse {
//         float: right;
//     }
// }

// @media screen and (min-width: 960px) {
//     .devkit-accordion-wrapper {
//         width: 500px;
//     }
// }

/* Color Palette CSS */

.devkit-color-palette-wrapper {
     h2:first-of-type {
        margin-top: 24px;
    }

     .devkit-warning-message-wrapper {
        margin: 18px auto;
    }
     .devkit-cp-wrapper {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        height: auto;
         .devkit-color-wrapper {
            display: flex;
            flex-flow: column;
            justify-content: center;
            margin: 0 12px 12px 12px;

             .devkit-light-blue,
             .devkit-primary-blue,
             .devkit-lighter-grey,
             .devkit-slate,
             .devkit-green,
             .devkit-yellow,
             .devkit-red,
             .devkit-sky-blue,
             .devkit-apple-green,
             .devkit-varden,
             .devkit-tutu,
             .devkit-light-cyan {
                width: 150px;
                height: 150px;
            }

             .devkit-color-name,
             .devkit-hex-text,
             .devkit-rgb-text {
                font-weight: 600;
            }

             .devkit-color-hex {
                margin-top: 12px;
            }

             .devkit-color-name {
                font-size: 18px;
            }

             .devkit-color-hex,
             .devkit-color-rgb {
                padding-left: 18px;
            }

             .devkit-color-hex,
             .devkit-color-rgb,
             .devkit-hex-text,
             .devkit-rgb-text {
                font-size: 14px;
            }
        }

         .devkit-light-blue {
            background: $light-blue;
        }

         .devkit-primary-blue {
            background: $primary-blue;
        }

         .devkit-lighter-grey {
            background: $lighter-grey;
        }

         .devkit-slate {
            background: $slate;
        }

         .devkit-green {
            background: $green;
        }

         .devkit-yellow {
            background: $yellow;
        }

         .devkit-red {
            background: $red;
        }

         .devkit-sky-blue {
            background: $sky-blue;
        }

         .devkit-apple-green {
            background: $applegreen;
        }

         .devkit-varden {
            background: $varden;
        }

         .devkit-tutu {
            background: $tutu;
        }

         .devkit-light-cyan {
            background: $light-cyan;
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-color-palette-wrapper {
         .devkit-cp-wrapper {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            align-items: center;
            max-width: 500px;

             .devkit-color-wrapper {
                display: flex;
                margin: 0 18px 18px 0;

                 .devkit-light-blue,
                 .devkit-primary-blue,
                 .devkit-lighter-grey,
                 .devkit-slate,
                 .devkit-green,
                 .devkit-yellow,
                 .devkit-red,
                 .devkit-sky-blue,
                 .devkit-apple-green,
                 .devkit-varden,
                 .devkit-tutu,
                 .devkit-light-cyan {
                    width: 120px;
                    height: 120px;
                    margin-right: 12px;
                }

                 .devkit-color-name {
                    width: auto;
                    text-align: center;
                }

                 .devkit-color-hex,
                 .devkit-color-rgb {
                    text-align: left;
                }
            }
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-color-palette-wrapper {

         .devkit-warning-message-wrapper {
            margin: 24px auto;
        }
         .devkit-cp-wrapper {
            max-width: 700px;
             .devkit-color-wrapper {
                margin-bottom: 24px;
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    .devkit-color-palette-wrapper {
         .devkit-cp-wrapper {
            max-width: 1200px;

             .devkit-color-wrapper {
                margin-bottom: 36px;

                 .devkit-light-blue,
                 .devkit-primary-blue,
                 .devkit-lighter-grey,
                 .devkit-slate,
                 .devkit-green,
                 .devkit-yellow,
                 .devkit-red,
                 .devkit-sky-blue,
                 .devkit-apple-green,
                 .devkit-varden,
                 .devkit-tutu,
                 .devkit-light-cyan {
                    width: 150px;
                    height: 150px;
                    margin-right: 12px;
                }
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .devkit-color-palette-wrapper {
         .devkit-cp-wrapper {
            max-width: 1400px;
        }
    }
}

/* Buttons CSS */
/* Following CSS rules cannot be altered. */
.form-button-wrapper {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
     a:link,
     a:visited,
     a:hover {
        color: $white;
        text-decoration: none;
    }
}
.link-button {
    background: none;
    text-decoration: underline;
    color: $ocean-blue;
    font-size: 16px;margin: 6px;
}
.primary,
.secondary {
    color: $white;
    text-decoration: none;
    cursor: pointer;
    margin: 6px 6px 6px 0;
    border: 0;
    border-radius: 0;
}

.primary {
    background: $primary-blue;
}

.secondary {
    background: $secondary-grey;
}

.disabled {
    margin: 6px 6px 4px 0;
    padding: 10px;
    background: $cyan;
    color: $slate;
    opacity: 0.8;
    cursor: not-allowed;
}

.primary.mini,
.secondary.mini,
.disabled.mini {
    padding: 4px 5px 3px 5px;
    font-size: 12px;
}

.primary.small,
.secondary.small,
.disabled.small {
    padding: 6px 7px 5px 7px;
    font-size: 12px;
}

.primary.default,
.secondary.default,
.disabled.default {
    padding: 9px 15px;
    font-size: 14px;
}

.primary.large,
.secondary.large,
.disabled.large {
    padding: 12px 15px 10px 15px;
    font-size: 18px;
}

.primary:hover,
.secondary:hover {
    background: $hover-blue;
}

/* Following CSS rules are used for demo purposes in this app. It can be altered. */
.devkit-buttons-wrapper {
     .devkit-buttons-description {
        font-weight: 600;
    }

     h2 {
        margin: 12px auto;
    }
     h3:first-of-type {
        margin-top: 12px;
    }
     p {
        margin-right: 12px;
         a:link {
            color: $link-active;
        }
         a:hover {
            color: $black;
            text-decoration: underline;
        }
         a:visited {
            color: $purple;
        }
    }
     .devkit-button-heading {
        display: block;
        font-size: 16px;
        font-weight: 600;
        margin: 24px auto 6px auto;
    }

     button {
        margin-right: 6px;
        border: 0;
    }

     a:link,
     a:visited,
     a:hover {
        color: $white;
        text-decoration: none;
    }
}

@media screen and (min-width: 960px) {
    .devkit-buttons-wrapper {
         p {
            margin-right: 36px;
        }
    }
}

/* Icons CSS */
.devkit-icons-table-wrapper {
    margin-bottom: 24px;
     h3 {
        margin-bottom: 12px;
    }
     p {
        margin: 0;
        line-height: 30px;
    }
     .devkit-table {
        width: 70%;
        border: solid 1px $light-grey;
        border-collapse: collapse;
        border-spacing: 0;
        margin: 24px 0 0 0;
        padding: 0;
        table-layout: fixed;
        color: $slate;

         .devkit-table-title {
            padding: 10px 9px 8px 18px;
            background: $light-blue;
            color: $white;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            text-align: left;
        }

         thead {
             th {
                padding: 10px 10px 10px 18px;
                text-align: left;
                border: solid 1px $light-grey;
                font-weight: 600;
                color: $dark-blue;
            }
        }

         tbody {
             td {
                border: solid 1px $light-grey;
                padding: 10px 10px 10px 18px;
            }
        }

         tr:hover {
            font-weight: 600;
        }

        tr.devkit-table-row {
            :nth-child(even) {
                background: $table-grey;
            }
            :nth-child(odd) {
                background: $white;
            }
        }
        .fa {
            font-size: 24px;
        }
    }
}

@media screen and (max-width: 600px) {
    .devkit-icons-table-wrapper {
         .devkit-table {
            width: 100%;
        }
    }
}

/* Skip To Main CSS */
#devkit-skip-to-main {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    margin-left: 0;

     a {
        position: absolute;
        left: -9999px;
        top: 142px;
    }

     a:focus,
     a:active {
        position: relative;
        left: 6px;
        z-index: 100;
        height: auto;
        margin: 0;
        padding: 6px;
        text-decoration: none;
        outline: none;
    }
}

@media screen and (min-width: 768px) {
    #devkit-skip-to-main {
         a {
            top: 162px;
        }
    }
}

@media screen and (min-width: 960px) {
    #devkit-skip-to-main {
         a {
            top: 204px;
        }
    }
}

@media screen and (min-width: 1280px) {
    #devkit-skip-to-main {
         a {
            top: 238px;
        }
    }
}

/* Devkit Version CSS*/
.devkit-version-wrapper {
    display: flex;
    justify-content: flex-end;
    height: 30px;
    margin: 12px 12px 0 0;
    font-size: 16px;
    font-style: italic;
    color: $slate;
}

@media screen and (min-width: 960px) {
    .devkit-version-wrapper {
        margin: 6px 24px 0 0;
    }
}

/* Guidelines CSS*/
.devkit-guidelines-wrapper {
     .devkit-warning-message-wrapper {
        margin: 24px auto;
    }

     img {
        width: 90%;
        height: auto;
        display: block;
        margin-top: 24px;
        margin-bottom: 24px;
    }

     .devkit-code-wrapper {
        display: flex;
        flex-flow: column;
        align-content: center;
    }
}

@media screen and (min-width: 960px) {
    .devkit-guidelines-wrapper {
         .devkit-desc-list {
             li {
                line-height: 30px;
            }
        }

         h2 {
            margin-top: 24px;
        }

         h4 {
            margin: 0;
        }

         .devkit-warning-message-wrapper {
            margin: 36px auto;

             .devkit-message-body {
                font-size: 18px;
            }
        }
         .devkit-code-wrapper {
            display: flex;
            flex-flow: row;
            justify-content: flex-start;
            margin: 36px auto;

             .devkit-code:first-of-type {
                padding-right: 48px;
                border-right: 2px solid $darker-grey;
            }

             .devkit-code:last-of-type {
                padding-left: 48px;
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .devkit-guidelines-wrapper {
         img {
            width: 75%;
            margin-top: 36px;
            margin-bottom: 36px;
        }
    }
}

/* Home Content CSS */
.devkit-home-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 500;
    color: $slate;
    line-height: 24px;

     p {
        margin: 6px 0;
    }

     .devkit-help-link {
        margin-bottom: 18px;
    }

     .devkit-help-link-bold {
        margin-top: 18px;
        font-weight: 600;
    }
}

/*Status Tracker CSS */
.devkit-progressbar-wrapper {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    flex-grow: 1;

     h3 {
        margin-top: 0;
        margin-left: 12px;
    }

     .devkit-cb-wrapper {
        display: flex;
        flex-flow: row;
        margin-bottom: 24px;

         .devkit-circle-wrapper {
            display: flex;
            flex-flow: column;
            align-items: center;
            margin-right: 24px;
            padding-top: 33px;

             .fa-check-circle,
             .fa-info-circle,
             .fa-times-circle {
                font-size: 36px;
            }

             .fa-spinner{
                padding: 2px;
                border-radius: 50%;
                background: $yellow;
                color: $white;
                font-size: 30px;
            }

             .fa-check-circle {
                color: $green;
            }

             .fa-spinner {
                border-radius: 50%;
                background: $yellow;
                color: $white;
            }

             .fa-times-circle {
                color: $red;
            }

             .devkit-ns-circle {
                border-radius: 50%;
                width: 36px;
                height: 36px;
            }

             .devkit-ns-circle {
                background: $background-grey;
            }

             .devkit-completion-bar,
             .devkit-ns-bar,
             .devkit-inprogress-bar,
             .devkit-error-bar {
                width: 6px;
                height: 110px;
            }

             .devkit-completion-bar {
                background: $green;
            }

             .devkit-inprogress-bar {
                background: $yellow;
            }

             .devkit-ns-bar {
                background: $light-grey;
            }

             .devkit-error-bar {
                background: $red;
            }
        }

         .devkit-status-wrapper {
            display: flex;
            flex-flow: column;
            align-items: center;

             .devkit-status-text {
                width: 180px;
                height: 73px;
                padding: 6px;
                background: $lighter-grey;
                border: solid 1px $light-grey;
                color: $slate;
                font-size: 14px;
                justify-content: center;
            }

             .devkit-hr {
                width: 2px;
                height: 60px;
                background: $darker-grey;
            }
        }
    }

     .devkit-progressbar {
        display: flex;
        flex-flow: row;
        width: 100%;
        margin-top: 36px;
        margin-bottom: 24px;

         .devkit-bar,
         .devkit-percentage {
            height: 20px;
        }

         .devkit-bar {
            width: 30%;
            background: $dark-blue;
        }

         .devkit-percentage {
            width: 70%;
            background: $lighter-grey;
        }
    }

    .devkit-loading {
        display: block;
        width: 100%;
        margin-bottom: 48px;
         .fa-spinner,
         .devkit-loading-text {
            display: inline;
        }

         .fa-spinner {
            font-size: 24px;
        }

         .devkit-loading-text {
            margin-left: 12px;
            font-size: 18px;
            font-weight: 600;
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-progressbar-wrapper {

         h3 {
            margin-left: 0;
        }

         .devkit-progressbar {
            width: 400px;
            margin-top: 24px;
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-progressbar-wrapper {
         h3:last-of-type {
            margin-left: 12px;
        }
         .devkit-cb-wrapper {
             .devkit-circle-wrapper {
                margin-bottom: 0;
                margin-right: 48px;
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    .devkit-progressbar-wrapper {
        display: flex;
        flex-flow: column;

         h3:last-of-type {
            margin-left: 0;
        }

          .devkit-cb-wrapper {
            display: flex;
            flex-flow: column;
             margin-bottom: 36px;

             .devkit-circle-wrapper {
                display: flex;
                flex-flow: row;
                align-items: center;
                order: 1;
                padding-left: 44px;
                padding-top: 0;
                margin-bottom: 24px;
                margin-right: 0;

                 .devkit-ns-circle {
                    min-width: 30px;
                    max-width: 30px;
                    height: 30px;
                }

                 .devkit-completion-bar,
                 .devkit-ns-bar,
                 .devkit-inprogress-bar,
                 .devkit-error-bar {
                    min-width: 95px;
                    max-width: 95px;
                    height: 6px;
                }
            }

             .devkit-status-wrapper {
                display: flex;
                flex-flow: row;
                align-items: center;
                text-align: center;
                font-size: 16px;
                order: 2;

                 .devkit-status-text {
                    min-width: 100px;
                    max-width: 100px;
                    height: 130px;
                }

                 .devkit-hr {
                    min-width: 18px;
                    max-width: 18px;
                    height: 2px;
                }
            }
        }

         .devkit-progressbar {
            width: 600px;
            margin-top: 36px;
            margin-bottom: 48px;
        }
    }
}

@media screen and (min-width: 1600px) {
    .devkit-progressbar-wrapper {

         .devkit-cb-wrapper {
             .devkit-circle-wrapper {
                padding-left: 70px;
                padding-top: 0;
                margin-bottom: 24px;
                margin-right: 0;

                 .devkit-ns-circle {
                    min-width: 36px;
                    height: 36px;
                }

                 .devkit-completion-bar,
                 .devkit-ns-bar,
                 .devkit-inprogress-bar,
                 .devkit-error-bar {
                    min-width: 150px;
                    height: 6px;
                }
            }

             .devkit-status-wrapper {
                 .devkit-status-text {
                    min-width: 150px;
                    height: 90px;
                }

                 .devkit-hr {
                    min-width: 24px;
                    height: 2px;
                }
            }
        }
    }
}

/* Tabs CSS */
.devkit-tabs-wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-left: 12px;
}

@media screen and (min-width: 960px) {
    .devkit-tabs-wrapper {
        justify-items: flex-start;
    }
}

@media screen and (min-width: 1280px) {
    .devkit-tabs-wrapper {
        flex-direction: row;
        justify-content: flex-start;
    }
}

@media screen and (min-width: 1600px) {
    .devkit-tabs-wrapper {
        flex-direction: row;
        justify-content: space-between;
    }
}

/* Alerts  Dialogs */
/* Error Box CSS */
.devkit-error-message-wrapper {
    display: flex;
    width: auto;
    margin: 0 24px 12px 12px;
    font-size: 16px;
    background: $tutu;
    border-left: 11px solid $red;

     .devkit-message-heading-wrapper {
         .fa-times-circle {
            padding: 3px;
            font-size: 36px;
            padding-left: 20px;
            padding-top: 24px;
        }
    }

     .devkit-message-body-wrapper {
        width: 100%;
        font-weight: 400;
        color: $slate;
        line-height: 24px;

         .fa-times {
            float: right;
            margin-top: 0;
            padding: 9px 12px 9px 9px;
            color: $slate;
            font-size: 16px;
        }

         .devkit-message-heading {
            font-size: 26px;
            font-weight: 600;
            padding: 24px 24px 0 14px;
        }

         .devkit-message-body {
            margin: 12px 0 0 0;
            font-size: 16px;
            padding: 0 24px 24px 14px;
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-error-message-wrapper {
        margin-right: 0;
    }
}

@media screen and (min-width: 960px) {
    .devkit-error-message-wrapper {
        margin-right: 0;
        margin-left: 0;
    }
}

/* Info box CSS */
.devkit-info-message-wrapper {
    display: flex;
    width: auto;
    margin: 0 24px 12px 12px;
    font-size: 16px;
    background: $light-cyan;
    border-left: 11px solid $sky-blue;

     .devkit-message-heading-wrapper {
         .fa-info-circle,
         .fa-exclamation-triangle {
            padding: 3px;
            font-size: 18px;
            padding-left: 12px;
            padding-top: 18px;
        }
    }

     .devkit-message-body-wrapper {
        width: 100%;
        font-weight: 400;
        color: $slate;
        line-height: 18px;

         .fa-times {
            float: right;
            margin-top: 0;
            padding: 6px 9px 6px 6px;
            color: $slate;
            font-size: 16px;
        }

         .devkit-message-heading {
            font-size: 18px;
            font-weight: 600;
            padding: 18px 18px 0 8px;
        }

         .devkit-message-body {
            margin: 12px 0 0 0;
            font-size: 16px;
            padding: 0 18px 18px 8px;
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-info-message-wrapper {
        margin-right: 0;
    }
}

@media screen and (min-width: 960px) {
    .devkit-info-message-wrapper {
        margin-right: 0;
        margin-left: 0;
    }
}

/* Loading box CSS */
.devkit-loading-message-wrapper {
    display: block;
    width: 250px;
    margin: 0 24px 12px 12px;
    font-size: 16px;
    border: solid 1px $light-grey;

     .fa-times {
        float: right;
        padding: 9px;
        color: $slate;
        font-size: 12px;
    }

     .devkit-message-heading-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 12px 12px 3px 12px;
        color: $black;
        font-weight: 600;

         .fa-spinner {
            padding: 3px;
            font-size: 22px;
        }

         .message-heading {
            margin-left: 6px;
        }
    }

     .devkit-message-body {
        padding: 3px 12px 12px 46px;
        font-size: 14px;
        font-weight: 400;
        color: $black;
    }
}

@media screen and (min-width: 960px) {
    .devkit-loading-message-wrapper {
        margin-bottom: 60px;
        margin-right: 0;
        margin-left: 0;
    }
}

/* Pod box CSS*/
.devkit-pod-box-wrapper {
    font-size: 16px;
    margin: 0 24px 12px 12px;
    /* margin-top: 14px; */

     .devkit-pod-heading {
        width: 280px;
        display: flex;
        align-items: center;
        height: 40px;
        background: $dialog-background;
        color: $white;
        font-weight: 700;
        padding: 0 15px;
    }

     .devkit-pod-body {
        width: 278px;
        border: solid 1px $light-grey;
        border-top: none;
        padding: 15px;
        color: $slate;
        line-height: 24px;

         p {
            margin-top: 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-pod-box-wrapper {
        margin-right: 0;
    }
}

@media screen and (min-width: 960px) {
    .devkit-pod-box-wrapper {
        margin-right: 0;
        margin-left: 0;
    }
}

/* Success box CSS */
.devkit-success-message-wrapper {
    display: flex;
    width: auto;
    margin: 0 24px 12px 12px;
    font-size: 16px;
    background: $applegreen;
    border-left: 11px solid $green;

     .devkit-message-heading-wrapper {
         .fa-check-circle {
            padding: 3px;
            font-size: 36px;
            padding-left: 20px;
            padding-top: 24px;
        }
    }

     .devkit-message-body-wrapper {
        width: 100%;
        font-weight: 400;
        color: $slate;
        line-height: 24px;

         .fa-times {
            float: right;
            margin-top: 0;
            padding: 9px 12px 9px 9px;
            color: $slate;
            font-size: 16px;
        }

         .devkit-message-heading {
            font-size: 26px;
            font-weight: 600;
            padding: 24px 24px 0 14px;
        }

         .devkit-message-body {
            margin: 12px 0 0 0;
            font-size: 16px;
            padding: 0 24px 24px 14px;
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-success-message-wrapper {
        margin-right: 0;
    }

}

@media screen and (min-width: 960px) {
    .devkit-success-message-wrapper {
        margin-top: 24px;
        margin-left: 0;
    }

}

/* Warning box CSS */
.devkit-warning-message-wrapper {
    display: flex;
    width: auto;
    margin: 0 24px 12px 12px;
    font-size: 16px;
    background: $varden;
    border-left: 11px solid $yellow;

     .devkit-message-heading-wrapper {
         .fa-exclamation-circle {
            padding: 3px;
            font-size: 36px;
            padding-left: 20px;
            padding-top: 24px;
        }
    }

     .devkit-message-body-wrapper {
        width: 100%;
        font-weight: 400;
        color: $slate;
        line-height: 24px;

         .fa-times {
            float: right;
            margin-top: 0;
            padding: 9px 12px 9px 9px;
            color: $slate;
            font-size: 16px;
        }

         .devkit-message-heading {
            font-size: 26px;
            font-weight: 600;
            padding: 24px 24px 0 14px;
        }

         .devkit-message-body {
            margin: 12px 0 0 0;
            font-size: 16px;
            padding: 0 24px 24px 14px;
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-warning-message-wrapper {
        margin-right: 0;
    }
}

@media screen and (min-width: 960px) {
    .devkit-warning-message-wrapper {
        margin-left: 0;
    }
}

/* Dialog box CSS */
.devkit-dialog-wrapper {
    width: 300px;
    font-size: 16px;
    margin: 0 24px 12px 12px;

     .devkit-dialog-heading-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        background: $dialog-background;
        color: $white;
        font-weight: 700;
        padding: 0 15px;
    }

     .devkit-dialog-body {
        border: solid 1px $light-grey;
        border-top: none;
        padding: 15px;
        color: $slate;
        line-height: 24px;

         p {
            margin-top: 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-dialog-wrapper {
        width: 400px;
        margin-right: 0;
         .devkit-dialog-body {
            height: 400px;
            margin-right: 0;
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-dialog-wrapper {
        width: 500px;
        margin-left: 0;
    }
}

/* FORMS */
/* Checkbox/Radio forms CSS */
.devkit-checkradio-wrapper {
    display: flex;
    color: $slate;
    margin-left: 0;

     fieldset.devkit-checkradio-fieldset {
        padding: 12px;
        min-width: 300px;
        font-size: 14px;
    }

     fieldset {
        border: 1px solid transparent;
        border-radius: 3px;
        clear: both;
        margin: 12px 0;
        padding: 0;

         ul.devkit-sample-checkbox,
         ul.devkit-sample-radio-button {
            padding-left: 0;
            list-style-type: none;

             li {
                display: flex;
                align-items: center;
                margin-bottom: 0;

                 input {
                    display: none;
                }
            }
        }
    }
}

/* Group Multi Select CSS*/
.devkit-group-multi-select-form-wrapper {
    display: flex;
    justify-content: flex-start;
    color: $slate;
    margin-left: 0;
    font-size: 14px;

     fieldset.devkit-group-select-fieldset-wrapper {
        padding: 12px;
        min-width: 280px;
    }

     fieldset {
        border: 1px solid transparent;
        border-radius: 3px;
        margin: 12px 0;
        padding: 0;
    }

     .devkit-label-wrapper {
         .devkit-dropdown-label {
            display: block;
            font-weight: 600;
        }

         .devkit-group-select-dropdown {
            display: block;
            width: 220px;
            margin-top: 12px;
            background-image: url("../assets/images/arrow-both.png");
            background-position: right 20px center;
            background-repeat: no-repeat;
            background-size: 12px;
        }

         .devkit-group-select-dropdown {
            border: solid 1px $light-grey;
            padding: 6px;
        }
    }

     .devkit-group-select-options-wrapper {
        display: none;
        width: 232px;
        margin-left: inherit;
        padding-top: 24px;
        border-right: solid 1px $light-grey;
        border-left: solid 1px $light-grey;
        border-bottom: solid 1px $light-grey;

         .devkit-group-checkbox-wrapper {
             label {
                font-weight: 600;
                cursor: pointer;
            }
        }

         .devkit-group-children {
            cursor: pointer;
            margin-left: 24px;
            padding-left: 6px;
        }

         .devkit-group-checkbox-wrapper,
         .devkit-checkbox-wrapper {
            display: flex;
            align-items: center;
            padding-bottom: 6px;

             input {
                display: none;
            }
        }

         .devkit-group-checkbox-wrapper {
             [type=checkbox] + label {
                padding-left: 6px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-group-multi-select-form-wrapper {
         fieldset.devkit-group-select-fieldset-wrapper {
            min-width: 400px;
            padding: 24px;
            font-size: 16px;
        }

         .devkit-label-wrapper {
            display: flex;
            align-items: center;

             .devkit-dropdown-label,
             .devkit-multi-select-dropdown {
                display: inline-block;
            }

             .devkit-dropdown-label {
                width: 160px;
                text-align: right;
            }

             .devkit-group-select-dropdown {
                width: 200px;
                margin-left: 24px;
            }
        }

         .devkit-group-select-options-wrapper {
            width: 212px;
            margin-left: 184px; /* This margin will change based on the dropdown placement. */
        }
    }
}

/* Multiselect Input Form CSS */
.devkit-multi-select-form-wrapper {
    display: flex;
    justify-content: flex-start;
    color: $slate;
    margin-left: 0;
    font-size: 14px;

     fieldset.devkit-multi-select-fieldset-wrapper {
        padding: 12px;
        min-width: 280px;
    }

     fieldset {
        border: 1px solid transparent;
        border-radius: 3px;
        clear: both;
        margin: 12px 0;
        padding: 0;
    }

     .devkit-label-wrapper {
         .devkit-dropdown-label {
            display: block;
            font-weight: 600;
        }

         .devkit-multi-select-dropdown {
            display: block;
            width: 200px;
            margin-top: 12px;
            background-image: url("../assets/images/arrow-both.png");
            background-position: right 20px center;
            background-repeat: no-repeat;
            background-size: 12px;
        }

         .devkit-multi-select-dropdown {
            border: solid 1px $light-grey;
            padding: 6px;
        }
    }

     .devkit-multi-select-options-wrapper {
        display: none;
        width: 212px;
        margin-left: inherit;
        padding-top: 24px;
        border-right: solid 1px $light-grey;
        border-left: solid 1px $light-grey;
        border-bottom: solid 1px $light-grey;
    }

     .devkit-multi-checkbox-wrapper {
        display: flex;
        align-items: center;
        padding-bottom: 6px;

         input {
            display: none;
        }

         [type=checkbox] + label {
            padding-left: 6px;
        }
    }

     .devkit-pills-wrapper {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        max-width: 360px;
        margin-left: 24px;
         .devkit-pill {
            display: none;

             .devkit-pill-wrapper {
                min-width: 120px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-right: 6px;
                margin-bottom: 6px;
                padding: 6px 9px 2px 9px;
                font-size: 14px;
                font-weight: 500;
                background: $light-blue;
                color: $white;
                border-radius: 9px;
                cursor: pointer;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-multi-select-form-wrapper {
         fieldset.devkit-multi-select-fieldset-wrapper {
            min-width: 300px;
            padding: 24px;
            font-size: 16px;
        }

         .devkit-label-wrapper {
            display: flex;
            align-items: center;

             .devkit-dropdown-label,
             .devkit-multi-select-dropdown {
                display: inline-block;
            }

             .devkit-dropdown-label {
                width: 150px;
                text-align: right;
            }

             .devkit-multi-select-dropdown {
                margin-left: 24px;
            }
        }

         .devkit-multi-select-options-wrapper {
            width: 212px;
            margin-left: 174px;
        }
    }
}

/* Single Select Dropdown CSS */
.devkit-single-select-dropdown-wrapper {
    display: flex;
    color: $slate;
    margin-left: 0;
    font-size: 16px;

     fieldset.devkit-single-select-fieldset-wrapper {
        padding: 12px;
        min-width: 300px;
    }

     fieldset {
        border: 1px solid transparent;
        border-radius: 3px;
        clear: both;
        margin: 12px 0;
        padding: 0;

         .devkit-form-row {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            margin-bottom: 30px;
            margin-bottom: 12px;
            padding: 9px 3px;

             label {
                font-size: 18px;
            }

             select::-ms-expand { /*This is to hide default dropwdown arrow in IE */
                display: none;
            }

             select {
                width: 280px;
                padding: 4px 48px 6px 6px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background: $white;
                background-image: url("../assets/images/arrow-both.png");
                background-position: right 20px center;
                background-repeat: no-repeat;
                background-size: 12px;
                color: $slate;
                font-size: 16px;

                 optgroup.devkit-optgroup {
                    color: $slate;
                    margin-top: 6px;
                    font-size: 16px;
                }

                 option {
                    padding: 6px;
                    font-size: 16px;
                }

                 option:hover {
                    background: $hover-blue;
                    color: $white;
                    padding: 10px 6px 0 6px;
                }
            }

             select:disabled {
                width: 300px;
                background: $background-grey;
            }
        }
    }

     .devkit-required {
        font-size: 26px;
        color: $red;
        vertical-align: middle;
    }
}

@media screen and (min-width: 768px) {
    .devkit-single-select-dropdown-wrapper {
         fieldset.devkit-select-fieldset-wrapper {
            min-width: 400px;
            padding: 24px;
            font-size: 16px;
        }

         fieldset {
             .devkit-form-row {
                margin-bottom: 12px;
                padding: 12px 6px;

                 select {
                    width: 320px;
                }
            }
        }
    }
}

/* Single select List Form CSS*/
.devkit-single-select-list-wrapper {
    display: flex;
    color: $slate;
    margin-left: 0;
    font-size: 16px;

     fieldset.devkit-single-select-fieldset-wrapper {
        padding: 12px;
    }

     fieldset {
        margin: 12px 0;
        padding: 0;
        border: 1px solid transparent;
        border-radius: 3px;
        clear: both;

         .devkit-form-row {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            margin-bottom: 12px;
            padding: 9px 3px;
            font-size: 18px;

             optgroup.devkit-optgroup {
                color: $slate;
                margin-top: 6px;
                font-size: 16px;
            }

             option {
                padding: 6px;
                font-size: 16px;
                color: $slate;
            }

             option:hover {
                background: $hover-blue;
                color: $white;
                padding: 8px 6px 4px 6px;
            }

             select:disabled {
                background: $background-grey;
            }
        }
    }

     .devkit-required {
        font-size: 26px;
        color: $red;
        vertical-align: middle;
    }
}

@media screen and (min-width: 768px) {
    .devkit-single-select-list-wrapper {
         fieldset.devkit-select-fieldset-wrapper {
            padding: 24px;
            font-size: 16px;
        }

         fieldset {
             .devkit-form-row {
                 select {
                    width: 350px;
                }
            }
        }
    }
}

/* The switch - the box around the slider */
.devkit-toggle-switch-wrapper {
    margin-top: 36px;
     .devkit-toggle-switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;

         input {
            opacity: 0;
            width: 0;
            height: 0;
        }
         .devkit-toggle {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: $grey;
            -webkit-transition: .4s;
            transition: .4s;
        }

         .devkit-toggle:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background:$white;
            -webkit-transition: .4s;
            transition: .4s;
        }

         input:checked + .devkit-toggle {
            background: $light-blue;
        }

         input:focus + .devkit-toggle {
            box-shadow: 0 0 1px $primary-blue;
        }

         input + .devkit-toggle:after {
            display: block;
            content: "Disabled";
            margin-top: 42px;
            margin-left: 2px;
            font-weight: 600;
        }

         input:checked + .devkit-toggle:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }

         input:checked + .devkit-toggle:after {
            display: block;
            content: "Enabled";
            margin-top: 42px;
            margin-left: 2px;
            font-weight: 600;
        }

         .devkit-toggle.devkit-round {
            border-radius: 34px;
        }

         .devkit-toggle.devkit-round:before {
            border-radius: 50%;
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-slider-wrapper {
        width: 500px;
    }
}

/* Textarea Input CSS*/
.devkit-textarea-input-form-wrapper {
    display: flex;
    margin-left: 0;
    color: $slate;

     fieldset.devkit-textarea-input-fieldset-wrapper {
        min-width: 300px;
        padding: 6px;
        font-size: 16px;
    }

     fieldset {
        border: 1px solid transparent;
        border-radius: 3px;
        clear: both;
        margin: 12px 0;
        padding: 0;

         textarea {
            padding-left: 6px;
        }

         .devkit-form-row {
            display: flex;
            flex-flow: column;
            margin-bottom: 30px;

             label {
                font-size: 18px;
            }
             textarea {
                width: 300px;
                border: solid 1px $darker-grey;
            }

             .devkit-character-count {
                align-self: flex-end;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-textarea-input-form-wrapper {
         fieldset.devkit-textarea-input-fieldset-wrapper {
            padding: 24px;
            font-size: 16px;
        }

         fieldset {
             .devkit-form-row {
                 textarea {
                    width: 420px;
                }
            }

        }
    }
}

/* Text Input Form CSS */
.devkit-text-input-form-wrapper {
    display: flex;
    color: $slate;
    margin-left: 0;

     fieldset.devkit-text-input-fieldset-wrapper {
        padding: 6px;
    }

     fieldset {
        border: 1px solid transparent;
        border-radius: 3px;
        clear: both;
        margin: 12px 0;
        padding: 0;

         .devkit-required {
            font-size: 26px;
            color: $red;
            vertical-align: bottom;
        }
         .devkit-input-error {
            max-width: 300px;
            border-left: 5px solid $red;
            margin-top: 30px;
            margin-bottom: 36px;
            padding-left: 18px;
            padding-top: 6px;
            position: relative;
            right: 22px;

             .devkit-input-error-message {
                color: $red;
                display: block;
                font-size: 20px;
                font-weight: 600;
                padding-bottom: 3px;
                padding-top: 3px;
            }

             label {
                font-size: 18px;
                font-weight: 600;
            }

             input {
                min-width: 300px;
                height: 20px;
                margin: 3px 0;
                padding: 12px 6px;
                font-size: 16px;
                border: 3px solid $red;
            }
        }

         .devkit-form-row {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            margin-bottom: 30px;

             label {
                font-size: 18px;
            }

             .devkit-input-success {
                border: 3px solid $green;
            }

             input,
             input[type='file'] {
                max-width: 300px;
                height: 20px;
                margin: 3px 0;
                padding: 12px 6px;
                border: solid 1px $darker-grey;
                font-size: 16px;
            }

             input::placeholder {
                font-size: 16px;
            }

             .form-button-wrapper {
                 .primary.default {
                    margin-right: 12px;
                    margin-left: 0;
                    padding: 7px 15px 6px 15px;
                    font-size: 14px;
                }

                 .secondary.default {
                    padding: 7px 15px 6px 15px;
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-text-input-form-wrapper {
        fieldset.devkit-text-input-fieldset-wrapper {
            padding: 24px;
            font-size: 16px;
        }

        fieldset {
            .devkit-form-row {
                input,
                input[type='file'] {
                    min-width: 420px;

                }
            }

            .devkit-input-error {
                right: 30px;
                input,
                input[type='file'] {
                    min-width: 426px;

                }
            }
        }
    }
}

/* Header Common CSS */
/* App Title */
.devkit-app-title,
.devkit-ext-app-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}

.devkit-app-title {
    a:link,
    a:hover,
    a:visited {
        color: $dark-blue;
        text-decoration: none;
    }
}

.devkit-ext-app-title {
    a:link,
    a:hover,
    a:visited {
        color: $white;
        text-decoration: none;
    }
}

@media screen and (min-width: 768px) {
    .devkit-app-title,
    .devkit-ext-app-title {
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
    }
}

@media screen and (min-width: 960px) {
    .devkit-app-title,
    .devkit-ext-app-title {
        font-size: 26px;
    }
}

@media screen and (min-width: 1280px) {
    .devkit-app-title,
    .devkit-ext-app-title {
        font-size: 32px;
    }
}

@media screen and (min-width: 1600px) {
    .devkit-site-logo,
    .devkit-ext-app-title {
        font-size: 40px;
        font-weight: 400;
    }
}

/* Greeting CSS */
.devkit-greeting-wrapper {
    display: none;
    /* Following CSS rules are used in larger screens and in hamburger menu. */
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    background: transparent;
}

@media screen and (min-width: 768px) {
    .devkit-greeting-wrapper {
        display: none;
        font-size: 18px;
    }
}

/* Hamburger Menu CSS */
.devkit-mobile-menu {
    width: 44px;
    height: 52px;
    display: flex;
    justify-content: center;
    .devkit-icon-wrapper {
        display: block;
        cursor: pointer;

        .fa-bars {
            display: block;
            width: 36px;
            padding-left: 13px;
            padding-top: 6px;
            font-size: 24px;
        }
        .devkit-menu-text {
            display: block;
            padding: 4px 4px 12px 6px;
            font-size: 14px;
            font-weight: 600;
        }
    }
}
.devkit-menu-overlay {
    display: none;
}

.devkit-mobile-menu-items {
    display: none;
}

.devkit-menu-overlay.active {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
	height:100%;
	width: 100%;
    background: $white;
    color: $slate;
	overflow: auto;
    z-index: 30;

    .devkit-greeting-wrapper {
        display: block;
        width: 260px;
        word-wrap: break-word;
         p {
            padding-left: 6px;
        }
    }

    .devkit-close-menu {
        display: block;
        position: absolute;
        top: 12px;
        right: 20px;
        font-size: 24px;
        color: $charcoal;
    }

    .devkit-mobile-menu-items.active {
        display: block;
        overflow: hidden;

        li-devkit-nav-link,
        li.devkit-inner-nav-link {
            list-style-type: none;
        }

        ul.devkit-nav-links {
            position: relative;
            display: block;
            padding-left: 6px;
            padding-right: 6px;

            li.devkit-nav-link,
            li.devkit-nav-link-nosub {
                display: block;
                line-height: 36px;
                border-bottom: solid 1px $lighter-grey;
                margin-bottom: 9px;
                font-weight: 600;
                a:link,
                a:visited,
                a:hover {
                    color: $charcoal;
                    text-decoration: none;
                }
            }

            li.devkit-nav-link {
                .devkit-main-menu-link:after {
                    content: '\25bc';
                    color: $dark-blue;
                    padding-left: 2px;
                    font-size: 16px;
                }
            }
        }

        li.devkit-nav-link {
            ul.submenu-nav {
                display: none;
            }
        }

        li.devkit-inner-nav-link {
            font-weight: 400;
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-menu-overlay.active {
        display: block;
        position: fixed;
        top: 0;
        height: 100%;
        width: 100%;
        background: $white;
        overflow: hidden;

        .devkit-greeting-wrapper {
            display: block;
            width: 600px;
            p {
                padding-left: 18px;
            }
        }

        .devkit-close-menu {
            top: 12px;
            right: 22px;
            font-size: 24px;
            color: $charcoal;
        }

        .devkit-mobile-menu-items.active {
            ul.devkit-nav-links {
                padding-left: 18px;
                padding-right: 18px;
            }
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-mobile-menu {
        display: none;
    }
}

/* Search Bar CSS */
.devkit-search-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 48px;

    button {
        padding: 0;
        margin-left: -5px;
    }

    input[type=text] {
        width: 270px;
        height: 22px;
        border-left: 2px solid $background-grey;
        border-bottom: 2px solid $background-grey;
        border-top: 2px solid $background-grey;
        border-right: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    input::placeholder {
        padding-left: 6px;
    }

    .devkit-btn-search,
    .devkit-close-search {
        display: none;
    }

    .devkit-search-icon {
        width: 32px;
        height: 28px;
        padding-right: 2px;
        font-size: 14px;
        font-weight: 600;
        border: 0px solid $lighter-grey;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background:$background-grey;
        color: $whaleblue;
        vertical-align: bottom;
    }
}


@media screen and (min-width: 768px) {
    .devkit-search-wrapper {
        display: block;
        padding-bottom: 60px;
        input[type=text] {
            width: 500px;
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-search-wrapper {
        display: none;
        position: absolute;
        top: 84px;
        right: 24px;
        z-index: 10;
        padding-bottom: 0;
        border-bottom: 0;
        input[type=text] {
            width: 380px;
        }

        .devkit-search-icon {
            display: none;
        }

        .devkit-btn-search,
        .devkit-close-search {
            display: inline;
            font-size: 14px;
            font-weight: 600;
            background: $background-grey;
            border: 0px solid $lighter-grey;
            color: $whaleblue;
            vertical-align: bottom;
        }

        .devkit-btn-search {
            width: 70px;
            height: 28px;
            border: 0px solid $lighter-grey;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .devkit-close-search {
            width: 30px;
            height: 28px;
            border-radius: 5px;
            margin-left: 1px;

            .fa-times {
                padding-top: 2px;
            }
        }
    }
}

/* Toolbar CSS*/
.devkit-toolbar-wrapper,
.devkit-ext-toolbar-wrapper {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-around;
    z-index: 8;
    cursor: pointer;

    .devkit-tool-bar-text {
        display: none;
    }

    .devkit-toolbar-icon {
        display: flex;
        align-items: center;

        .devkit-tool-bar-text {
            padding-left: 4px;
        }
        a:link,
        a:visited,
        a:hover {
            text-decoration: none;
        }

        .fa-search,
        .devkit-search-text {
            display: none;
        }

        .fa-search,
        .fa-user,
        .fa-user-alt,
        .fa-question-circle {
            font-size: 20px;
        }
    }

     #devkit-help-icon-wrapper {
        position: relative;

        .devkit-help-content-wrapper,
        .devkit-ext-help-content-wrapper {
            font-family: 'Helvetica Neue', Helvetica, sans-serif, Arial;
            display: none;
            position: absolute;
            top: 60px;
            z-index: 51;
            width: 320px;
            height: auto;
            padding: 0 24px 24px 24px;
            background-color: $table-grey;
            border: 1px solid $darker-grey;
            border-radius: 6px;
            box-shadow: 0 0 10px $darker-grey;

            .devkit-help-item-wrapper {
                display: block;
                padding-bottom: 18px;
                .item-title {
                    font-weight:600;
                }

                .item-content {
                    font-weight: 400;
                }

                .item-title,
                .item-content {
                    display: inline;
                    font-size: 16px;
                    color: $slate;
                    padding: 6px;
                }
            }

            .devkit-help-item-wrapper:first-of-type {
                margin-top: 18px;
            }

            .devkit-help-item-wrapper:last-of-type {
                padding-bottom: 12px;
            }
        }

        .devkit-help-content-wrapper {
            right: -9px;
        }
        .devkit-ext-help-content-wrapper {
            right: -16px;
        }

        .devkit-help-content-wrapper:before,
        .devkit-ext-help-content-wrapper:before {
            content: "";
            position: absolute;
            top: -42px;
            width: 0;
            height: 0;
            border: 10px solid transparent;
            border-bottom: 32px solid rgba(125,125,127,0.5);
            z-index: 2;
        }
        .devkit-help-content-wrapper:after,
        .devkit-ext-help-content-wrapper:after {
            content: "";
            position: absolute;
            top: -32px;
            width: 0;
            height: 0;
            border: 8px solid transparent;
            border-bottom: 25px solid $table-grey;
            z-index: 3;
        }
        .devkit-help-content-wrapper:before {
            right: 10px;
        }
        .devkit-ext-help-content-wrapper:before {
            right: 20px;
        }

        .devkit-help-content-wrapper:after {
            right: 12px;

        }
        .devkit-ext-help-content-wrapper:after {
            right: 22px;
        }
    }
}

.devkit-toolbar-wrapper {
    .devkit-toolbar-icon {
        .fa-search,
        .fa-user,
        .fa-user-alt,
        .fa-question-circle {
            color: $dark-blue;
        }
    }
    .devkit-tool-bar-text {
        color: $dark-blue;
    }
    .devkit-tool-bar-text.active {
        color: $dark-blue;
    }
}

.devkit-ext-toolbar-wrapper {
    .devkit-toolbar-icon {
        .fa-search,
        .fa-user,
        .fa-user-alt,
        .fa-question-circle {
            color: $white;
        }
    }
    .devkit-tool-bar-text.active {
        color: $white;
    }
}

@media screen and (min-width: 768px) {
    .devkit-toolbar-wrapper,
    .devkit-ext-toolbar-wrapper {
        .devkit-toolbar-icon {
            .fa-search,
            .fa-user,
            .fa-user-alt,
            .fa-question-circle {
                font-size: 24px;
            }
        }

        .devkit-tool-bar-text {
            display: inline;
            padding-right: 6px;
            font-size: 16px;
            font-weight: 600;
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-toolbar-wrapper,
    .devkit-ext-toolbar-wrapper {
        .devkit-toolbar-icon {
            .fa-search,
            .devkit-search-text {
                display: inline;
            }
        }

         #devkit-help-icon-wrapper {
            .devkit-help-content-wrapper {
                right: 20px;
            }
            .devkit-ext-help-content-wrapper {
                right: 2px;
            }
            .devkit-help-content-wrapper:before,
            .devkit-ext-help-content-wrapper:before {
                content: "";
                position: absolute;
                top: -42px;
                width: 0;
                height: 0;
                border: 10px solid transparent;
                border-bottom: 32px solid rgba(125,125,127,0.5);
                z-index: 2;
            }
            .devkit-help-content-wrapper:after,
            .devkit-ext-help-content-wrapper:after {
                content: "";
                position: absolute;
                top: -32px;
                width: 0;
                height: 0;
                border: 8px solid transparent;
                border-bottom: 25px solid $table-grey;
                z-index: 3;
            }
            .devkit-help-content-wrapper:before {
                right: 51px;
            }
            .devkit-ext-help-content-wrapper:before {
                right: 20px;
            }

            .devkit-help-content-wrapper:after {
                right: 53px;

            }
            .devkit-ext-help-content-wrapper:after {
                right: 22px;
            }
        }
    }
}

/* LISTS CSS */
/* Ordered Lists CSS */
.devkit-ordered-list-wrapper {
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: $slate;

    .devkit-ordered-list-label {
        font-weight: 600;
    }

    ol.devkit-outer {
        list-style-type: decimal;
    }

    ol.devkit-mid {
        list-style-type: lower-alpha;
    }

    ol.devkit-inner {
        list-style-type: lower-roman;
    }
}

/* Unordered Lists CSS */
.devkit-unordered-list-wrapper {
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: $slate;

    .devkit-unordered-list-label {
        font-weight: 600;
    }
}

/* NAVIGATION CSS */
/* Left Navigation CSS*/
.devkit-left-nav-link-wrapper {
    width: 200px;
    margin: 12px 0 12px 12px;

    .devkit-nav-heading {
        font-size: 20px;
        font-weight: 600;
        background: transparent;

        a:link,
        a:hover,
        a:visited {
            color: $link-active;
        }

        a:hover {
            text-decoration: underline;
        }
    }

    .devkit-nav-heading.active {
        a {
            font-weight: 600;
            color: $slate;
        }
    }

    ul.devkit-left-nav-links {
        padding-left: 0;
        padding-right: 18px;
        font-size: 16px;

        li {
            list-style-type: none;
            padding: 10px 6px;
            font-size: 16px;
            font-size: 500;
            border-top: 1px solid $light-grey;
            line-height: 24px;

            a:link,
            a:visited {
                color: $link-active;
                text-decoration: none;
            }

            a:hover {
                text-decoration: underline;
            }
        }

        li:last-of-type {
            border-bottom: 1px solid $light-grey;
        }

        li.active {
            a {
                font-size: 18px;
                font-weight: 600;
                color: $slate;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-left-nav-link-wrapper {
        width: 160px;
        margin: 6px;
    }
}

@media screen and (min-width: 960px) {
    .devkit-left-nav-link-wrapper {
        width: 200px;
        margin: 0 0 0 24px;
    }
}

@media screen and (min-width: 1280px) {
    .devkit-left-nav-link-wrapper {
        width: auto;
    }
}

@media screen and (min-width: 1600px) {
    .devkit-left-nav-link-wrapper {
        width: 240px;
    }
}

/* TABLES CSS */
/* Simple Table CSS */
.devkit-simple-table-wrapper {
    margin-bottom: 24px;
    .devkit-table {
        width: 98%;
        border: solid 1px $light-grey;
        border-collapse: collapse;
        border-spacing: 0;
        margin: 0;
        padding: 0;
        table-layout: fixed;
        color: $slate;

        .devkit-table-title {
            padding: 10px 9px 8px 16px;
            background: $light-blue;
            color: $white;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            text-align: left;
        }

        thead {
            th {
                padding: 10px;
                text-align: left;
                border: solid 1px $light-grey;
                border-left: none;
                border-right: none;
                font-weight: 600;
                color: $dark-blue;
            }
        }

        tbody {
            font-size: 14px;
            td {
                border: solid 1px $light-grey;
                padding: 10px;
                border-left: none;
                border-right: none;
            }
        }

        tr {
            .fa {
                padding-right: 9px;
                cursor: pointer;
            }
        }

        tr:hover {
            font-weight: 600;
        }

        tr.devkit-table-row:nth-child(even) {
            background: $table-grey;
        }

        tr.devkit-table-row:nth-child(odd) {
            background: $white;
        }
    }
}

@media screen and (max-width: 600px) {
    .devkit-simple-table-wrapper {
        .devkit-table {
            border: 1px solid $light-grey;
            border-bottom: 0;

            .devkit-table-title {
                padding: 10px 9px 8px 16px;
                background: $light-blue;
                color: $white;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                text-align: left;
            }

            thead {
                display: none;
            }

            tr {
                border-bottom: 1px solid $light-grey;
                display: block;
                margin-bottom: 0;
            }

            tbody {
                td {
                    display: block;
                    border: 0;
                    font-size: 14px;
                    text-align: right;
                }
            }

            td::before {
                content: attr(data-label);
                float: left;
                font-weight: 600;
                color: $dark-blue;
            }

            td:last-child {
                border-bottom: none;
            }
        }
    }
}

/* Merge Cells Table CSS*/
.devkit-merge-cells-table-wrapper {
    margin-bottom: 24px;
    .devkit-table {
        width: 98%;
        border: solid 1px $light-grey;
        border-collapse: collapse;
        border-spacing: 0;

        .devkit-table-title {
            padding: 10px 9px 8px 16px;
            background: $light-blue;
            color: $white;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            text-align: left;
        }

        thead,
        tr {
            th {
                min-width: 100px;
                padding: 4px;
                text-align: left;
                border: solid 1px $light-grey;
                font-weight: 600;
                color: $dark-blue;
            }
        }

        tbody {
            font-size: 14px;
            td {
                min-width: 100px;
                border: solid 1px $light-grey;
                padding: 10px;
            }
        }
    }

    tr:hover {
        font-weight: 600;
    }

    tr.devkit-table-row:nth-child(even) {
        background: $table-grey;
    }

    tr.devkit-table-row:nth-child(odd) {
        background: $white;
    }
}

@media screen and (min-width: 768px) {
    .devkit-merge-cells-table-wrapper {
        .devkit-table {
            .devkit-table-title {
                padding: 10px 9px 8px 16px;
                font-size: 16px;
                font-weight: 600;
            }

            thead {
                th {
                    padding: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .devkit-merge-cells-table-wrapper {
        .devkit-table {
            width: 100%;
            border: 1px solid $light-grey;
            border-bottom: 0;

            .devkit-table-title {
                padding: 10px 9px 8px 16px;
                background: $light-blue;
                color: $white;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                text-align: left;
            }

            thead {
                display: none;
                border: 0;
            }

            tr {
                display: block;
                border-bottom: 1px solid $light-grey;
                th {
                    border: 0;
                }
            }

            tbody {
                td {
                    display: block;
                    border: 0;
                    font-size: 14px;
                    text-align: right;
                }
            }

            td:before {
                content: attr(data-label);
                float: left;
                font-weight: 600;
                color: $dark-blue;
            }

            td:last-child {
                border-bottom: none;
            }
        }
    }
}

/* TABS */
/* Horizontal Tabs CSS */
.devkit-tab-wrapper {
    width: 330px;
    z-index: 20;
    margin-bottom: 24px;

    h3 {
        margin-top: 0;
        margin-left: 6px;
    }

    .devkit-tabbed-area {
        padding: 8px;
    }

    ul.devkit-tabs {
        margin: 0;
        padding-left: 0;

        li {
            display: inline;
            border-bottom-width: 0;
			margin: 0 1px 0 0;
            padding: 11px 14px 12px 14px;
            list-style: none;
            background: $tab-background;
            text-decoration: none;
            color: $link-active;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
        }

        li.active {
            background: $white;
            color: $slate;
            font-weight: 700;
            position: relative;
            top: 0;
            border: 1px solid $light-grey;
            border-bottom: 1px solid $white;
            outline: none;
        }

        li:hover {
            text-decoration: underline;
        }
    }

    .devkit-tab-content {
        display: none;
        margin-top: 6px;
        padding: 15px;
        font-size: 16px;
        color: $slate;
        line-height: 24px;
        border: 1px solid $light-grey;
    }

    .devkit-tab-content.active {
        display: block;
    }
}

@media screen and (min-width: 768px) {
    .devkit-tab-wrapper {
        width: 550px;
    }
}

@media screen and (min-width: 1280px) {
    .devkit-tab-wrapper {
        margin-right: 24px;
    }
}

/* Vertical Tabs CSS */
.devkit-vertical-tab-wrapper {
    width: 330px;
    z-index: 21;
    padding-left: 6px;

    h3  {
        margin-top: 0;
    }

    .devkit-vertical-tabbed-area {
        display: flex;
        align-items: flex-start;
    }

    ul.devkit-vertical-tabs {
        margin: 0;
        padding-top: 6px;
        padding-left: 0;

        li {
            display: block;
            width: 80px;
            border-right-width: 0;
            list-style: none;
            margin: 0 0 1px 0;
            padding: 10px 6px 6px 6px;
            background: $tab-background;
            text-decoration: none;
            color: $link-active;
            font-size: 16px;
            font-weight: 700;
            text-align: center;
            cursor: pointer;
        }

        li.active {
            background: $white;
            color: $slate;
            font-weight: 700;
            position: relative;
            right: -1px; /* we need this negative position for active vertical tab border. */
            border: 1px solid $light-grey;
            border-right: 0;
            outline: none;
        }

        li:hover {
            text-decoration: underline;
        }
    }

    .devkit-vertical-content {
        display: none;
        margin-top: 6px;
        min-height: 200px;
        padding: 15px;
        font-size: 16px;
        color: $slate;
        line-height: 24px;
        border: 1px solid $light-grey;
    }

    .devkit-vertical-content.active {
        display: block;
    }
}

@media screen and (min-width: 768px) {
    .devkit-vertical-tab-wrapper {
        width: 520px;
    }
}

@media screen and (min-width: 1500px) {
    .devkit-vertical-tab-wrapper {
        padding-left: 0;
    }
}

/* Error Pages */
.devkit-err-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 500px;
    margin-top: 12px;
    .devkit-err-message-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 1rem;
        color: $slate;
        font-weight: 500;
        .devkit-error-code {
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 12px;
        }
        .devkit-error-title {
            font-size: 1.125rem;
            font-weight: 600;
            margin-bottom: 12px;
        }
        .devkit-error-action-btns {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 12px;
            button {
                border: none;
                padding: 6px 12px;
            }
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-err-wrapper {
        flex-direction: row;
        align-items: center;
        margin-top: 24px;
        .devkit-err-message-wrapper {
            margin-left: 24px;
        }
    }
}

@media screen and (min-width: 1280px) {
    .devkit-err-wrapper {
        margin-top: 60px;
    }
}

/* COMMON - LAYOUT CSS*/
/* This is needed to remove grey border on top of
external main content area in mobile phones.*/
/* Main Content Layout CSS */
.devkit-main-wrapper.devkit-no-border {
    border-top: 0;
}

@media screen and (min-width: 768px) {
    .devkit-main-wrapper {
        .devkit-main-head {
            margin-right: 0;
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-main-wrapper {
        border-top: 0;

        .devkit-main-head {
            margin-left: 0;
        }

        .devkit-main-content-wrapper {
            margin-left: 0;
        }
    }
}

/* Main Content Layout With No Global Nav Bar CSS */

/* This is needed to remove grey border on top of
external main content area in desktop mode, mobile and tablet modes.*/
.devkit-left-nav-main-wrapper.devkit-no-border {
    border-top: 0;
}

.devkit-left-nav-main-wrapper {
    margin: 12px auto;
    border-top: solid 2px $lighter-grey;

    .devkit-main-head {
        margin-left: 12px;
    }

    .devkit-content-wrapper {
        margin-left: 12px;

        p {
            line-height: 24px;
            margin-top: 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-left-nav-main-wrapper {
        .devkit-main-head {
            margin-top: 18px;
            margin-right: 0;
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-left-nav-main-wrapper {
        margin: 24px 0 0 0;

        .devkit-main-head {
            margin: 18px 0 0 0;
        }

        .devkit-content-wrapper {
            margin-right: 12px;
            line-height: 30px;

            p:first-of-type {
                margin-top: 0;
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    .devkit-left-nav-main-wrapper {
        margin-top: 9px;

        .devkit-main-head {
            margin: 24px 0 0 0;
        }

        .devkit-left-nav-link-wrapper {
            margin-top: 24px;
        }

        .devkit-content-wrapper {
            margin: 24px 0 0 0;
        }
    }
}
/* Main Content With Box Layout CSS*/

/* This is needed to remove grey border on top of
external main content area in mobile phones.*/
.devkit-main-content-box-wrapper.devkit-no-border {
    border-top: 0;
}

.devkit-main-content-box-wrapper {
    width: 100%;
    margin: 12px auto;
    border-top: solid 2px $lighter-grey;

    .devkit-main-head {
        margin-right: 12px;
        margin-left: 12px;
    }
    .devkit-pod-box-wrapper {
        margin-bottom: 12px;
    }
}

@media screen and (min-width: 768px) {
    .devkit-main-content-box-wrapper {
        .devkit-main-head {
            margin-right: 0;
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-main-content-box-wrapper {
        border-top: 0;

        .devkit-main-head {
            margin-right: 0;
            margin-left: 0;
        }
    }
}

/* Main Content With Forms Layout CSS*/

/* This is needed to remove grey border on top of
external main content area in mobile phones.*/
.devkit-forms-wrapper.devkit-no-border {
    border-top: 0;
}

.devkit-forms-wrapper {
    width: 100%;
    margin: 12px auto;
    border-top: solid 2px $lighter-grey;

     .devkit-forms-tab-wrapper {

        .devkit-vertical-tab-area {
            display: flex;
            align-items: flex-start;
            margin-top: 0;
        }

        ul.devkit-forms-tab {
            margin: 0;
            padding-top: 6px;
            padding-left: 0;

            li {
                display: block;
                width: 80px;
                border-right-width: 0;
                list-style: none;
                margin: 0 0 1px 0;
                padding: 10px 6px 6px 6px;
                background: $tab-background;
                text-decoration: none;
                color: $link-active;
                font-size: 16px;
                font-weight: 700;
                text-align: left;
                cursor: pointer;
                word-wrap: break-word;
            }

            li.active {
                background: $white;
                color: $slate;
                font-weight: 700;
                position: relative;
                right: -1px; /* we need this negative position for active vertical tab border. */
                border: 1px solid $light-grey;
                border-right: 0;
                outline: none;
            }

            li:hover {
                text-decoration: underline;
            }
        }

        .devkit-form-content {
            display: none;
            min-height: 510px;
            margin-top: 6px;
            padding: 15px;
            font-size: 16px;
            color: $slate;
            line-height: 24px;
            border: 1px solid $light-grey;
        }

        .devkit-form-content.active {
            display: block;
        }
    }

    .devkit-main-head {
        margin-left: 12px;
    }

    .devkit-forms-tab-wrapper {
        margin-left: 12px;
    }
}

@media screen and (min-width: 768px) {
    .devkit-forms-wrapper {
        .devkit-forms-tab-wrapper {
            ul.devkit-forms-tab {
                li {
                    width: 130px;
                }
            }
            .devkit-form-content {
                min-height: 420px;
            }
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-forms-wrapper {
        border-top: 0;
        .devkit-forms-tab-wrapper {
            .devkit-vertical-tab-area {
                display: block;
            }

            ul.devkit-forms-tab {
                margin: 0;
                padding-left: 0;
                display: flex;
                align-items: center;

                li {
                    width: 90px;
                    height: 90px;
                    border-bottom-width: 0;
                    margin: 0 1px 0 0;
                    padding: 8px 14px;
                    list-style: none;
                    background: $tab-background;
                    text-decoration: none;
                    color: $link-active;
                    font-size: 16px;
                    font-weight: 700;
                    cursor: pointer;
                }

                li.active {
                    background: $white;
                    color: $slate;
                    font-weight: 700;
                    position: relative;
                    top: 0;
                    right: 0;
                    border: 1px solid $light-grey;
                    border-bottom: 0;
                }

                li:hover {
                    text-decoration: underline;
                }
            }

            .devkit-form-content {
                display: none;
                margin-top: -2px;
                padding: 15px;
                font-size: 16px;
                color: $slate;
                line-height: 24px;
                border: 1px solid $light-grey;
            }

            .devkit-form-content.active {
                display: block;
            }
        }

        .devkit-main-head {
            margin-left: 0;
        }

        .devkit-forms-tab-wrapper {
            margin-right: 6px;
            margin-left: 0;
        }
    }
}

@media screen and (min-width: 1280px) {
    .devkit-forms-wrapper {
        .devkit-forms-tab-wrapper {
            ul.devkit-forms-tab {
                li {
                    width: 100px;
                    height: 74px;
                }
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .devkit-forms-wrapper {
        .devkit-forms-tab-wrapper {
            ul.devkit-forms-tab {
                li {
                    width: auto;
                    height: auto;
                }
            }
        }
    }
}

/* Home Content Layout CSS */
.devkit-home-content-wrapper {
    width: 100%;
    margin: 12px auto;
    border-top: solid 2px $lighter-grey;

    .devkit-home-content {
        margin-left: 12px;
    }
}

@media screen and (min-width: 960px) {
    .devkit-home-content-wrapper {
        .devkit-home-content {
            margin-left: 0;
        }
    }
}

/* Main Content With Table Layout CSS*/
/* This is needed to remove grey border on top of
external main content area in mobile phones.*/
.devkit-tabular-content-wrapper.devkit-no-border {
    border-top: 0;
}

.devkit-tabular-content-wrapper {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: $slate;
    line-height: 24px;
    margin: 12px auto;
    border-top: solid 2px $lighter-grey;

    .devkit-main-head  {
        margin-left: 12px;
        margin-right: 12px;
    }

    .devkit-simple-table-wrapper {
        margin-left: 12px;
    }

    .devkit-merge-cells-table-wrapper {
        margin-left: 12px;
    }
}

@media screen and (min-width: 768px) {
    .devkit-tabular-content-wrapper {
        .devkit-main-head {
            margin-right: 0;
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-tabular-content-wrapper {
        border-top: 0;

        .devkit-main-head {
            margin-left: 0;
        }

        .devkit-simple-table-wrapper {
            margin-left: 0;
        }

        .devkit-merge-cells-table-wrapper {
            margin-left: 0;
        }
    }
}

/* INTERNAL APP CSS*/
/*Internal Header CSS*/

header {

    .devkit-mobile-menu {
        color: $dark-blue;
        .fa-bars {
            color: $dark-blue;
        }
    }

    .devkit-app-title {
        display: flex;
        align-items: center;
    }

    .devkit-greeting-wrapper {
        display: none;
        color: $slate;
        span.devkit-greeting {
            font-weight: 600;
            color: $dark-blue;
        }
    }

    .devkit-toolbar-wrapper {
        padding-left: 12px;
    }
}

@media screen and (min-width: 768px) {
    header {
        margin: 0 auto;

        .devkit-app-title {
            align-items: flex-start;
        }

        .devkit-greeting-wrapper {
            display: none;
        }
    }
}

@media screen and (min-width: 960px) {
    header {
        margin: 0 auto;

        .devkit-mobile-menu {
            display: none;
        }

        .devkit-app-title {
            margin-left: 24px;
        }

        .devkit-greeting-wrapper {
            display: flex;
            justify-content: flex-end;
            margin-right: 20px;
        }

        .devkit-search-wrapper {
            display: none;
            top: 112px;
            right: 24px;
        }

        .devkit-toolbar-wrapper {
            padding-left: 0;
        }
    }
}

@media screen and (min-width: 1280px) {
    header {
        margin: 0 auto;

        .devkit-greeting-wrapper {
            margin-right: 12px;
        }
    }
}
/* Internal Nav Bar CSS */
/* This file has styles for horizontal navigation bar.
For vertical navigation styles, please refer to sideNav.css */

.devkit-nav-wrapper {
    display: none;
}

@media screen and (min-width: 768px) {
    .devkit-nav-wrapper {
        display: none;
    }
}

@media screen and (min-width: 960px) {
    .devkit-nav-wrapper {
        display: block;
        background: $lighter-grey;
        height: 42px;

        ul.devkit-nav-links {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 600;
            padding: 0;
            margin: 0;

            li.devkit-nav-link-nosub,
            li.devkit-nav-link,
            li.devkit-inner-nav-link {
                list-style: none;
            }

            li.devkit-nav-link-nosub,
            li.devkit-nav-link {
                padding: 11px 0 12px 0;
                a {
                    text-decoration: none;
                }

                a:link,
                a:visited {
                    color: $slate;
                }

                a.devkit-main-menu-link {
                    padding: 17px 7px 11px 7px;
                }
            }

            li.devkit-nav-link {
                .devkit-main-menu-link:after {
                    content: '\25bc';
                    color: $dark-blue;
                    padding-left: 2px;
                    font-size: 16px;
                }

                ul.submenu-nav {
                    display: none;
                    min-width: 200px;
                    position: absolute;
                    background: $white;
                    margin: 8px 0 6px 0;
                    z-index: 30;
                    padding: 10px 18px;
                    li.devkit-inner-nav-link {
                        display: block;
                        margin-left: 0;
                        text-align: left;
                    }
                    li.devkit-inner-nav-link {
                        border-bottom: solid 1px $lighter-grey;
                        border-radius: 5px;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 40px;

                        a.devkit-submenu-link:hover {
                            padding: 10px 9px;
                            background: $light-grey;
                            border-radius: 4px;
                        }

                        a.devkit-submenu-link:link,
                        a.devkit-submenu-link:visited {
                            padding: 10px 9px;
                        }
                    }

                    li.devkit-inner-nav-link:last-of-type {
                        border-bottom: 0;
                    }
                }

                ul.submenu-nav.side-by-side {
                    max-width: 380px;
                    width: 100%;
                    .left {
                        float: left;
                        width: 45%;
                        clear: left;
                        padding-left: 6px;
                        .left-subhead {
                            padding-left: 6px;
                        }
                    }
                    .right {
                        float: right;
                        width: 45%;
                        clear: right;
                        padding-left: 6px;
                        .right-subhead {
                            padding-left: 6px;
                        }
                    }
                    .left {
                        li:last-of-type {
                            border-bottom: 0;
                        }
                    }
                }
                ul.submenu-nav.nav-open {
                    display: block;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    box-shadow: 0 0 4px 2px rgba(51,51,51,.1);
                }
            }

            li.devkit-nav-link:hover,
            li.devkit-nav-link-nosub:hover {
                a.devkit-main-menu-link {
                    background: $light-grey;
                }
            }
        }
    }
}


@media screen and (min-width: 1280px) {
    .devkit-nav-wrapper {
        height: 60px;

        ul.devkit-nav-links {
            font-size: 18px;
            font-weight: 700;

            li.devkit-nav-link,
            li.devkit-nav-link-nosub {
                padding: 20px 5px 16px 5px;

                ul.submenu-nav {
                    min-width: 240px;
                    margin: 15px 0 6px 0;
                    padding-left: 6px;
                    padding-right: 12px;
                }

                a.devkit-main-menu-link {
                    padding: 25px 8px 18px 8px;
                }
            }

            li.devkit-nav-link.active,
            li.devkit-nav-link-nosub.active {
                background: $white;
                color: $slate;
                outline: none;
                border-bottom: 5px solid $light-grey;
                border-right: 1px solid $light-grey;
                border-left: 1px solid $light-grey;
            }
        }
    }
}

@media screen and (min-width: 1500px) {
    .devkit-nav-wrapper {
        ul.devkit-nav-links {
            li.devkit-nav-link,
            li.devkit-nav-link-nosub {
                padding: 20px 5px 16px 5px;

                a.devkit-main-menu-link {
                    padding: 25px 8px 18px 8px;
                }
            }
        }
    }
}

/* Internal Global - Top Bar CSS*/
.devkit-top-bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 18px;
    padding-top: 10px;
    padding-bottom: 8px;
    color: $white;
    background: $light-blue;

    span.devkit-top-bar-text {
        margin-left: 12px;
        font-size: 16px;
        font-weight: 400;

        span.devkit-faa-text {
            font-weight: 600;
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-top-bar {
        height: 36px;

        span.devkit-top-bar-text {
            margin-left: 12px;
            font-size: 24px;
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-top-bar {
        height: 36px;

        span.devkit-top-bar-text {
            margin-left: 24px;
            font-size: 24px;
        }
    }
}

/* Internal Global - Bottom Bar CSS */
.devkit-bottom-bar-wrapper {
    height: 72px;
    padding: 4px 0;
    margin: 24px 12px 12px 12px;
    background: linear-gradient($bottom-bar-dark-blue, $bottom-bar-darker-blue);
    border-radius: 10px;
    .devkit-logo-wrapper {
        display: flex;
        align-items: center;
        margin-left: 18px;
        margin-top: 6px;
        img {
            width: 60px;
            height: 60px;
        }

        .devkit-logo-title {
            color: $white;
            width: 200px;
            font-size: 18px;
            font-weight: 600;
            margin-left: 6px;
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-bottom-bar-wrapper {
        height: 90px;
        padding: 4px 0;
        margin: 48px 24px 24px 24px;
        .devkit-logo-wrapper {
            img {
                width: 72px;
                height: 72px;
            }

            .devkit-logo-title {
                font-size: 24px;
            }
        }
    }
}

/* INTERNAL FOOTER CSS*/
/* Internal Footer Nav Links CSS */
.devkit-footer-groups,
.devkit-info-group-links {
    .devkit-footer-group-title {
        margin-left: 6px;
        margin-top: 12px;
        font-size: 14px;
        font-weight: 400;
    }

    ul.devkit-footer-links {
        display: block;
        padding-left: 6px;
        margin: 0;

        li {
            display: inline;
            font-weight: 300;
            list-style-type: none;
            line-height: 6px;
            font-size: 14px;
            margin-right: 9px;
        }
    }
}

@media screen and (min-width: 768px) {
    .devkit-footer-groups,
    .devkit-info-group-links {
        .devkit-footer-group-title {
            margin-left: 0;
            margin-bottom: 12px;
            padding-top: 24px;
            font-size: 24px;
            font-weight: 300;
        }

        ul.devkit-footer-links {
            display: block;
            padding-left: 0;

            li:last-of-type {
                margin-bottom: 8px;
            }

            li {
                display: block;
                margin-bottom: 16px;
                font-size: 16px;
            }
        }
    }
}

/* Internal Footer Logo CSS*/
.devkit-footer-logo-wrapper {
    padding-left: 6px;
    font-size: 24px;
    font-weight: 400;

    a:link,
    a:visited,
    a:hover {
        color: $light-blue;
        text-decoration: none;
    }

    .devkit-footer-special {
        color: $dark-blue;
    }
}

@media screen and (min-width: 768px) {
    .devkit-footer-logo-wrapper {
        padding-left: 24px;
        font-size: 48px;
        font-weight: 600;
    }
}

/* Internal Footer Layout CSS*/

.devkit-footer-wrapper {
    padding-bottom: 12px;
    background: linear-gradient($footer-lighter-grey-background, $footer-light-grey-background);
    border-radius: 5px;
    border-top: solid 1px $light-grey;
    clear: both;
}

footer {
    padding-left: 12px;
}

@media screen and (min-width: 768px) {
    footer {
        .devkit-footer-logo-wrapper {
            padding-top: 24px;
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-footer-wrapper {
        padding-bottom: 24px;
        background: linear-gradient($footer-lighter-grey-background,$footer-light-grey-background);
        border-radius: 5px;
        border-top: solid 1px $light-grey;
        clear: both;
    }
}

/* EXTERNAL/PUBLIC FACING CSS */
/* External/Public Facing Header Nav CSS*/
.ext-head-nav-wrapper {
    display: none;
}

@media screen and (min-width: 768px) {
    .ext-head-nav-wrapper {
        display: none;
    }
}

@media screen and (min-width: 960px) {
    .ext-head-nav-wrapper {
        ul.ext-head-nav-items {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-left: 0;
            margin: 0;
            li.ext-head-nav-item {
                padding: 2px 6px;
                border-radius: 4px;
                color: $white;
                cursor: pointer;
                font-weight: 700;
                list-style-type: none;
                a:link,
                a:visited {
                    color: $white;
                    /** These are needed to avoid li items shifting to the right when hovering the link. **/
                    padding: 6px;
                    border-radius: 4px;
                    background: $external-nav-active-background;
                    text-decoration: none;
                }
                a:hover {
                    padding: 6px;
                    border-radius: 4px;
                    background: $external-nav-active-background;
                    color: $white;
                }
            }
            li.faa-other {
                background: $external-gold;
                position: relative;
                ul.ext-nav-dropdown {
                    display: none;
                    list-style-type: none;
                }
            }
            li.faa-other:hover {
                ul.ext-nav-dropdown {
                    display: block;
                    width: 275px;
                    background: rgba(255, 255, 255, .98);
                    border: 3px solid $external-gold;
                    border-radius: 4px 0 4px 4px;
                    margin: 0;
                    padding-left: 0;
                    position: absolute;
                    right: 0;
                    z-index: 60;
                    li.ext-head-submenu-item {
                        display: block;
                        padding: 6px;
                        color: $slate;
                        border-bottom: 1px solid $external-list-border;
                        font-weight: 500;
                        a:link,
                        a:visited,
                        a:hover {
                            color: $slate;
                        }
                    }
                    li.ext-head-submenu-item:hover {
                        background: $external-gold;
                    }
                }
            }
        }
    }
}

/* External/Public Facing Header Logo CSS */
.devkit-ext-site-logo {
    display: flex;
    align-items: center;
    // margin-top: 12px;
    img {
        display: none;
    }

    .devkit-logo-title {
        color: $white;
        width: 200px;
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
    }
}

@media screen and (min-width: 768px) {
    .devkit-ext-site-logo {
        .devkit-logo-title {
            font-size: 16px;
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-ext-site-logo {
        margin-top: 0;
        img {
            display: inline;
            width: 84px;
            height: 84px;
            padding-bottom: 6px;
        }

        .devkit-logo-title {
            font-size: 36px;
            line-height: 24px;
        }
    }
}

/* External Header Layout CSS*/

div.ext-header-wrapper {
    background: $external-dark-blue;
    color: $white;
    border-bottom: 5px solid $ext-header-top-border;

    .ext-header {
        width: auto;
        margin: 0 auto;
        padding-top: 6px;
        padding-bottom: 6px;
        .devkit-mobile-menu {
            color: $white;
            .fa-bars {
                color: $white;
            }
        }

        .devkit-ext-site-logo {
            margin-left: 12px;
        }

        .devkit-ext-app-title {
            display: flex;
            align-items: center;
        }

        .devkit-greeting-wrapper {
            display: none;
            color: $white;
            span.devkit-greeting {
                font-weight: 600;
                color: $white;
            }
        }

        .ext-head-nav-wrapper {
            display: none;
        }

        .devkit-ext-toolbar-wrapper {
            padding-right: 6px;
            color: $white;
        }
    }
}

@media screen and (min-width: 768px) {
    div.ext-header-wrapper {
        .ext-header {
            .devkit-ext-app-title {
                align-items: flex-start;
            }

            .devkit-greeting-wrapper {
                display: none;
            }

            .ext-head-nav-wrapper {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 960px) {
    div.ext-header-wrapper {
        .ext-header {
            padding-top: 0;
            padding-bottom: 0;
            .mobile-menu {
                display: none;
            }

            .devkit-ext-site-logo {
                margin-left: 14px;
            }

            .devkit-greeting-wrapper {
                display: flex;
                justify-content: flex-end;
                margin-right: 20px;
            }

            .devkit-search-wrapper {
                display: none;
                top: 80px;
                right: 24px;
            }

            .devkit-ext-toolbar-wrapper {
                padding-left: 0;
                padding-bottom: 12px;
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    div.ext-header-wrapper {
         .ext-header {
             .mobile-menu {
                display: none;
            }
            .devkit-greeting-wrapper {
                display: flex;
                justify-content: flex-end;
                margin-right: 36px;
            }

            .devkit-search-wrapper {
                display: none;
            }

            .devkit-ext-toolbar-wrapper {
                padding-left: 0;
                padding-bottom: 12px;
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    div.ext-header-wrapper {
        .ext-header {
            .mobile-menu {
                display: none;
            }

            .devkit-greeting-wrapper {
                display: flex;
                justify-content: flex-end;
            }

             .devkit-search-wrapper {
                display: none;
            }

            .devkit-ext-toolbar-wrapper {
                padding-left: 0;
            }
        }
    }
}

/* External/Public Facing Nav Bar CSS*/
/* This file has styles for horizontal navigation bar.
For vertical navigation styles, please refer to sideNav.css */

.devkit-ext-nav-wrapper {
    display: none;
}

@media screen and (min-width: 768px) {
    .devkit-ext-nav-wrapper {
        display: none;
    }
}

@media screen and (min-width: 960px) {
    .devkit-ext-nav-wrapper {
        display: block;
        background: $light-blue;
        height: 42px;
        margin: 0 auto 12px auto;

        ul.devkit-nav-links {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 600;
            padding: 0;
            margin: 0;

            li.devkit-nav-link-nosub,
            li.devkit-nav-link,
            li.devkit-inner-nav-link {
                list-style: none;
            }

            li.devkit-nav-link-nosub,
            li.devkit-nav-link {
                padding: 9px 0 10px 0;
                a {
                    text-decoration: none;
                }

                a:link,
                a:visited {
                    color: $white;
                }

                a:active {
                    color: $light-blue;
                }

                a.devkit-main-menu-link {
                    padding: 16px 7px 10px 7px;
                }
            }

            li.devkit-nav-link-nosub.active {
                a.devkit-main-menu-link:hover {
                    padding: 16px 7px 10px 7px;
                }
            }

            li.devkit-nav-link {
                .devkit-main-menu-link {
                    color: $white;
                }
                .devkit-main-menu-link:after {
                    content: '\25bc';
                    color: $white;
                    padding-left: 2px;
                    font-size: 16px;
                }
            }

            li.devkit-nav-link {
                ul.submenu-nav {
                    display: none;
                    min-width: 200px;
                    position: absolute;
                    background: $white;
                    margin: 8px 0 6px 0;
                    z-index: 30;
                    padding: 10px 18px;

                    li.devkit-inner-nav-link {
                        border-bottom: solid 1px $lighter-grey;
                        border-radius: 5px;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 40px;

                        a.devkit-submenu-link:link,
                        a.devkit-submenu-link:visited {
                            color: $slate;
                            padding: 10px 9px;
                        }

                        a.devkit-submenu-link:hover {
                            background: $external-dark-blue;
                            border-radius: 4px;
                            color: $white;
                            padding: 4px 12px 20px 9px;
                            text-decoration: none;
                        }
                    }

                    li.devkit-inner-nav-link,
                    span.devkit-submenu-heading {
                        display: block;
                        margin-left: 0;
                        text-align: left;
                    }

                    li.devkit-inner-nav-link:last-of-type {
                        border-bottom: 0;
                    }
                }

                ul.submenu-nav.side-by-side {
                    max-width: 380px;
                    width: 100%;
                    .left {
                        float: left;
                        width: 45%;
                        clear: left;
                        padding-left: 6px;
                        .left-subhead {
                            padding-left: 6px;
                        }
                    }
                    .right {
                        float: right;
                        width: 45%;
                        clear: right;
                        padding-left: 6px;
                        .right-subhead {
                            padding-left: 6px;
                        }
                    }
                    .left {
                        li:last-of-type {
                            border-bottom: 0;
                        }
                    }
                }
                ul.submenu-nav.nav-open {
                    display: block;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    box-shadow: 0 0 4px 2px rgba(51,51,51,.1);
                }
            }

            li.devkit-nav-link:hover,
            li.devkit-nav-link-nosub:hover {
                a.devkit-main-menu-link {
                    background: $ext-microhead-background;
                }
            }

            li.devkit-nav-link.active,
            li.devkit-nav-link-nosub.active {
                background: $external-dark-blue;
                color: $slate;
                outline: none;
                border-bottom: 5px solid $light-blue;
                border-right: 1px solid $light-blue;
                border-left: 1px solid $light-blue;
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    .devkit-ext-nav-wrapper {
        height: 60px;

        ul.devkit-nav-links {
            font-size: 18px;
            font-weight: 700;

            li.devkit-nav-link,
            li.devkit-nav-link-nosub {
                padding: 20px 5px 16px 5px;
                ul.submenu-nav {
                    min-width: 240px;
                    margin: 16px 0 6px 0;
                    padding-left: 6px;
                    padding-right: 12px;
                }

                a.devkit-main-menu-link {
                    padding: 26px 8px 18px 8px;
                }
            }
            li.devkit-nav-link-nosub.active {
                a.devkit-main-menu-link:hover {
                    padding: 23px 8px 18px 8px;
                }
            }
        }
    }
}

/* External/Public Facing Global Top Bar CSS */

.devkit-dot-microbar-wrapper {
    background: $ext-microhead-background;
    color: $white;
    .devkit-dot-microbar,
    .devkit-fw-dot-microbar {
        width: auto;
        a:link,
        a:visited,
        a:hover {
            color: $white;
            text-decoration: none;
        }
        .dot-logo-wrapper {
            display: flex;
            align-items: center;
            margin-left: 12px;
            img {
                width: 18px;
                height: 18px;
                vertical-align: middle;
                background: $white;
                border-radius: 50%;
            }
            .dot-logo-title {
                margin-left: 2px;
                font-size: 14px;
            }
        }
        .dot-links-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-left: 12px;

            ul {
                list-style-type: none;
                padding-left: 0;
                margin-top: 2px;
                margin-bottom: 2px;
                li:last-of-type {
                    margin-right: 0;
                }
                li {
                    display: inline;
                    margin-right: 18px;
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-dot-microbar-wrapper {
        .devkit-fw-dot-microbar {
            width: 960px;
            margin: 0 auto;
            padding: 0 1%;
        }
        .devkit-dot-microbar {
            width: 100%;
        }
        .devkit-fw-dot-microbar {
            .dot-logo-wrapper {
                margin-left: 0;
            }
            .dot-links-wrapper {
                margin-right: 0;
            }
        }
        .devkit-dot-microbar {
            width: 100%;
            .dot-logo-wrapper {
                margin-left: 28px;
            }
            .dot-links-wrapper {
                margin-left: 0;
                margin-right: 36px;
            }
        }

        .devkit-dot-microbar,
        .devkit-fw-dot-microbar {
            .dot-links-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
}

/* External/Public Facing Bottom Bar CSS*/

.devkit-ext-btmbar-wrapper {
    width: 100%;
    background: linear-gradient($white, $ext-dot-footer-gradient);
    padding: 12px 0 24px;

    .devkit-ext-btmbar,
    .devkit-ext-fw-btmbar {
        width: auto;
        margin: 0 auto;

        .btmbar-content {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            padding: 0 6px;

            .logo-addr-wrapper {
                display: flex;
                align-items: center;
                margin-top: 12px;
                p {
                    line-height: 16px;
                }
                img,
                .faa-addr {
                    display: inline-block;
                }
                img {
                    width: 63px;
                    height: 64px;
                }
                .faa-addr {
                    padding-left: 9px;
                }
            }

            .btmbar-col {
                h2 {
                    font-size: 22px;
                    line-height: 30px;
                }
                ul {
                    list-style-type: none;
                    padding-left: 0;

                    li {
                        display: block;
                        margin-top: 6px;
                        font-size: 15px;
                        a:link,
                        a:hover,
                        a:visited {
                            color: $link-active;
                        }

                        a:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-ext-btmbar-wrapper {
        padding: 6px 0 12px;

        .devkit-ext-btmbar {
            width: 100%;
        }

        .devkit-ext-fw-btmbar {
            width: 100%;
            max-width: 960px;
            margin: 0 auto;
            padding: 0 1%;
        }

        .devkit-ext-btmbar,
        .devkit-ext-fw-btmbar {
            .btmbar-content {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: flex-start;
                justify-content: space-between;
                padding: 0;

                .logo-addr-wrapper {
                    img {
                        width: 96px;
                        height: 96px;
                    }
                    .faa-addr {
                        padding-left: 6px;
                    }
                }
            }
        }

        .devkit-ext-btmbar {
            .logo-addr-wrapper {
                margin-left: 24px;
            }
            .btmbar-col:last-of-type {
                margin-right: 24px;
            }
        }
    }
}

/* EXTERNAL/PUBLIC FACING FOOTER CSS*/
/* External/Public Facing Footer Nav CSS*/
.devkit-ext-flinks-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .devkit-flinks-col:not(:last-child) {
        padding-right: 16px;
    }

    .devkit-flinks-col {
        h2 {
            font-size: 22px;
            line-height: 30px;
        }
        ul {
            list-style-type: none;
            padding-left: 0;
            li {
                display: block;
                margin-top: 10px;
                font-size: 15px;

                a:link,
                a:hover,
                a:visited {
                    color: $white;
                }

                a:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

@media screen and (min-width: 960px) {
    .devkit-ext-flinks-wrapper {
        flex-wrap: nowrap;
    }
}

/* External/Public Facing Footer Layout CSS */
/* Start of No Blue Footer CSS */
.devkit-ext-no-footer-wrapper {
    display: table;
    width: 100%;
    border-top: 3px solid $ext-footer-top-border;
    border-bottom: 6px solid $primary-blue;
}
/*End of No Blue Footer CSS */

.devkit-ext-footer-wrapper {
    display: block;
    width: 100%;
    background: $primary-blue;
    color: $white;
    border-top: 3px solid $ext-footer-top-border;
    border-bottom: 2px solid $ext-footer-bottom-border;

    .ext-footer,
    .ext-fw-footer {
        width: auto;
        margin: 0 auto;
    }
}

@media screen and (min-width: 960px) {
    .devkit-ext-footer-wrapper {
        .ext-footer {
            width: 100%;
        }
        .ext-fw-footer {
            width: 100%;
            max-width: 960px;
            margin: 0 auto;
            padding: 0 1%;
        }
        .ext-footer {
            .devkit-ext-flinks-wrapper {
                .devkit-flinks-col:first-of-type {
                    margin-left: 24px;
                }

                .devkit-flinks-col:last-of-type {
                    margin-right: 48px;
                }
            }
        }
    }
}



















